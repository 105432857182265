<template>
  <div class="w-full text-gray-900 labelingBox" v-if="layout.dataset" style="min-width: 1200px; z-index: 9999999;height: 100%;background-color: #a0aec0;">

    <div class="grid grid-cols-12 h-full">
      <div class="col-span-12 sm:col-span-7 lg:col-span-9 xxl:col-span-10 overflow-y-auto h-full" style="-ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll;"
        :style="`background-image: url(${require(`@/assets/images/rosepetal/icon/back-labeling.png`)}); background-position: center; background-size: cover; background-repeat: no-repeat; height: 100%; min-height: 100vh;`">
        <!-- FILTERS -->
        <div id="rpFiltersLabeling"  class="box rounded-none pl-2 pr-5 py-1 pb-2.5 bg-gray-200 border-b border-gray-400 bg-gray-200">
          <div class="flex flex-row h-8">
            <div class="inline-block h-8 mr-2"><img :src="require(`@/assets/images/rosepetal/icon/logoLoading.png`)" class="w-9" /></div>
            <div class="form-check pr-5 ml-3 mt-1">
              <input id="mediaName" type="checkbox" :checked="layout.optImages.mediaName" class="form-check-input bg-white" @change="layout.optImages.mediaName = layout.optImages.mediaName ? false : true"  />
              <label class="form-check-label pt-0.5" for="mediaName">{{ $t('Name') }}</label>
            </div>
            <div class="form-check px-5 border-l mt-1" v-if="layout.dataset.type=='MULTICLASS' || layout.dataset.type=='MULTILABEL'">
                <input id="mediaLabel" type="checkbox" :checked="layout.optImages.mediaLabel" class="form-check-input bg-white" @change="layout.optImages.mediaLabel = layout.optImages.mediaLabel ? false : true;" /> <!-- if(layout.dataset.type=='' || layout.dataset.type=='MULTILABEL')reloadimageObjectDetection()-->
                <label class="form-check-label pt-0.5" for="mediaLabel">{{ $t('Tags') }}</label>
            </div>
            <div class="form-check px-5 border-l mt-1" v-if="layout.dataset.type=='MULTICLASS' || layout.dataset.type=='MULTILABEL'">
                <input id="mediaMask" type="checkbox" :checked="layout.optImages.mediaMask" class="form-check-input bg-white" @change="layout.optImages.mediaMask = layout.optImages.mediaMask ? false : true; reload()"  />
                <label class="form-check-label pt-0.5" for="mediaMask">{{ $t('Mask') }}</label>
                <div class="intro-x dropdown w-7 h-7">
                  <div class="dropdown-toggle w-7 h-7 image-fit zoom-in scale-110 text-center mt-1" role="button" aria-expanded="true">
                    <MoreVerticalIcon class="w-4 h-4 ml-2" />
                  </div>
                  <div class="dropdown-menu w-52 rpLabelingTopMenu">
                    <div class="dropdown-menu__content box bg-gray-200">
                      <div class="p-4">
                        <div class="text-sm pr-3 ">{{ $t('Opacity') }}:</div>
                        <div class="form-check mr-2 pr-3 w-20 mt-1">
                          <div class="align-middle">
                            <input type="range" name="quantity" min="0" max="1" step="0.1" v-model="layout.optImages.maskOpacity" class="cursor-pointer mt-1.5" 
                            :disabled="!layout.dataset?.images?.media || !layout.dataset?.images?.media.length ? true : false">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="form-check px-5 border-l mt-1">
                <input id="mediaSet" type="checkbox" :checked="layout.optImages.mediaSet" class="form-check-input bg-white" @change="layout.optImages.mediaSet = layout.optImages.mediaSet ? false : true"  />
                <label class="form-check-label pt-0.5" for="mediaSet">{{ $t('Set') }}</label>
            </div>
            <div class="form-check px-5 border-l mt-1">
                <input id="mediaDate" type="checkbox" :checked="layout.optImages.mediaDate" class="form-check-input bg-white" @change="layout.optImages.mediaDate = layout.optImages.mediaDate ? false : true"  />
                <label class="form-check-label pt-0.5" for="mediaDate">{{ $t('Date') }}</label>
            </div>
            <template v-if="layout.dataset?.images?.media && layout.dataset?.images?.media.length">
              <div class="form-check mr-2 border-l pr-3 w-60 mt-1">
                <span class="text-sm pr-3 pl-5">{{ $t('Size') }}:</span>
                <span class="inline-block align-middle">
                  <input type="range" name="quantity" min="2" max="4" v-model="layout.optImages.mediaSize" class="cursor-pointer mt-1.5" :disabled="!layout.dataset?.images?.media || !layout.dataset?.images?.media.length ? true : false">
                </span>
              </div>
              <!--
              <div class="form-check mr-5 border-l w-8 mr-1 cursor-pointer" v-if="layout.dataset?.description && !layout.optImages.info"><img class="w-5 h-5 inline-block ml-3.5" :src="require(`@/assets/images/rosepetal/icon/info.png`)" title="Show Info" @click="layout.optImages.info = true"/> </div>
              <div class="form-check mr-5 border-l w-11 mr-1 cursor-pointer" v-else-if="layout.dataset?.description"><img class="w-5 h-5 inline-block ml-3.5" :src="require(`@/assets/images/rosepetal/icon/infoHide.png`)" title="Hide Info" @click="layout.optImages.info = false"/></div>
              <div class="text-center text-gray-600 mr-5 ml-2 text-xs" v-if="layout.dataset?.description && layout.optImages.info">
                <marquee behavior="scroll" direction="left" class="cursor-default pt-2 m-0">
                  {{ layout.dataset?.description }}. <span v-if="layout.optImages.objByTag">Selected tag is {{ layout.optImages.objByTag  }}.</span>
                </marquee>
              </div>
              -->
              <div class="form-check mr-5 border-l w-52 mt-1">
                <span class="text-sm pr-5 pl-4">{{ $t('Order') }}:</span>
                <select class="w-32 form-select p-1 pl-3 cursor-pointer bg-white" v-model="layout.optImages.order" >
                  <option value="created">{{$t('Created')}}</option>
                  <!--
                  <template v-if="layout.dataset.type!='imageObjectDetection' && layout.dataset.type!='MULTILABEL'"><option value="updated">{{$t('Updated')}}</option></template>
                  -->
                </select>
              </div>
              <div class="form-check mr-5 border-l w-96 mt-1">
                <span class="text-sm pr-5 pl-4">{{ $t('Per page') }}:</span>
                <select class="w-20 form-select p-1 pl-3 cursor-pointer bg-white" v-model="layout.optImages.perPage" >
                    <option v-bind:value="12">12</option>
                    <option v-bind:value="50">50</option>
                    <option v-bind:value="125">125</option>
                    <option v-bind:value="250">250</option>
                  </select>
              </div>
              <div class="float-right ml-auto" v-if="layout.dataset?.images?.pagination && !layout.loading">
                <ul class="pagination float-right ml-auto mr-5">
                  <li class="w-10" v-if="layout.dataset.images.pagination.prev"><button class="pagination__link" @click="layout.optImages.action='init'; reload()"><ChevronsLeftIcon class="w-8 h-8 text-gray-500 hover:text-blue-500" /></button></li>
                  <li class="w-10" v-if="layout.dataset.images.pagination.prev"><button class="pagination__link" @click="layout.optImages.action='prev'; reload()"><ChevronLeftIcon class="w-8 h-8 text-gray-500 hover:text-blue-500" /></button></li>
                  <li><span class="pagination__link cursor-default" v-if="layout.dataset.images.pagination.pages>2 && (layout.dataset.images.pagination.currentPage+1)>2"><span class="text-gray-500">...</span></span></li>
                  <li v-for="(n) in (layout.dataset.images.pagination.pages ?? 0)" :key="n"  :class="layout.dataset.images.pagination.next || layout.dataset.images.pagination.prev ? 'mt-1.5' : 'mt-1'">
                      <a class="pagination__link pl-0 pr-0 cursor-default" :class="n==(layout.dataset.images.pagination.currentPage+1) ? 'bg-gray-400 text-theme 5' : 'text-blue-500'"
                      v-if="n<((layout.dataset.images.pagination.currentPage+1)+2) && n>((layout.dataset.images.pagination.currentPage+1)-2)">{{ n }}</a>
                  </li>
                  <li ><span class="pagination__link cursor-default text-blue-500" v-if="layout.dataset.images.pagination.pages>2 && (layout.dataset.images.pagination.currentPage+1)<(layout.dataset.images.pagination.pages-1)"><span class=" text-gray-500">...</span></span></li>
                  <li class="w-10" v-if="layout.dataset.images.pagination.next"> <button class="pagination__link" @click="layout.optImages.action='next'; reload()"><ChevronRightIcon class="w-8 h-8 text-gray-500 hover:text-blue-500" /></button></li>
                  <li class="w-10" v-if="layout.dataset.images.pagination.next && layout.dataset.images.pagination.toend"><a class="pagination__link" @click="layout.optImages.action='end'; reload()"><ChevronsRightIcon class="w-8 h-8 text-gray-500 hover:text-blue-500" /></a></li>
                </ul>
              </div>
            </template>
          </div>
        </div> 
        <!--<div class="grid grid-cols-12" v-else><div class="col-span-12"><div class="box rounded-none px-5 py-0.5 bg-gray-100 border-b border-gray-400 bg-gray-200 text-center"><div class="w-12 inline-block h-9"></div></div></div></div>-->
        <!-- SELECTION AND ACTIONS -->
        <div v-if="countCheck() && !layout.loading" class="grid grid-cols-12">
          <div class="col-span-12 bg-white">
              <div class="flex rounded px-5 py-1 mx-auto bg-white">
                <div class="flex items-center pl-3">
                  <span class="text-blue-500 mr-5 cursor-pointer" @click="check()">{{ $t('Select all') }}</span>
                  <span class="text-blue-500 cursor-pointer" @click="unCheck()">{{ $t('Deselect all') }}</span>
                  <!--
                  <button class="btn flex items-center bg-blue-600 border-gray-400 text-white py-1 px-5 mr-2 text-sm font-normal" @click="check()">{{ $t('Select all') }}</button>
                  <button class="btn flex items-center bg-gray-300 border-gray-400 text-gray-900 py-1 text-sm font-normal" @click="unCheck()" v-if="selectedImages.length"><XIcon class="hidden sm:block w-4 h-4 mr-1" />{{ $t('Unselect') }}</button>
                  -->
                </div> 
                <div class="flex pl-12 py-1 items-center">
                    <span class="mr-10"><span class="text-3xl font-bold align-middle pr-2">{{countCheck()}}</span><span class="align-middle text-sm">{{ $t('media selected') }}</span></span>
                    <button v-if="layout.dataset.type=='MULTICLASS'" class="btn flex items-center bg-gray-100 border-gray-300 text-gray-900 py-1 px-5 mr-2 text-sm font-normal hover:bg-blue-500 hover:text-white" @click="modal('modal-labeling')">{{ $t('Assign tag') }}</button>
                    <button class="btn flex items-center bg-gray-100 border-gray-300 text-gray-900 py-1 px-5 mr-2 text-sm font-normal hover:bg-blue-500 hover:text-white" @click="modal('modal-change-set')">{{ $t('Data division') }}</button>
                    <!--<button class="btn flex items-center bg-gray-100 border-gray-300 text-gray-900 py-1 px-5 mr-2 text-sm font-normal hover:bg-blue-500 hover:text-white" @click="modal('modal-labeling-job-box')">{{ $t('Labeling Job') }}</button>-->
                    <button class="btn flex items-center bg-gray-100 border-gray-300 text-gray-900 py-1 px-5 mr-2 text-sm font-normal hover:bg-green-800 hover:text-white" @click="modal('modal-download-selected')">{{ $t('Download') }}</button>
                    <button class="btn flex items-center bg-gray-100 border-gray-300 text-gray-900 py-1 px-5 mr-2 text-sm font-normal hover:bg-green-800 hover:text-white" @click="layout.move.success = false; layout.move.toDataset = false; modal('modal-move-selected'); ">{{ $t('Move') }}</button>
                    <button class="btn flex items-center bg-gray-100 border-gray-300 text-gray-900 py-1 px-5 mr-2 text-sm font-normal hover:bg-green-800 hover:text-white" @click="layout.copy.success = false; layout.copy.toDataset = false; modal('modal-copy-selected')">{{ $t('Copy') }}</button>
                    <button class="btn flex items-center bg-gray-100 border-gray-300 text-gray-900 py-1 px-5 mr-2 text-sm font-normal hover:bg-red-500 hover:text-white" @click="modal('modal-delete')"><TrashIcon class="w-5 h-5 cursor-pointer" /></button>
                    <!--
                    <a class="btn flex-1 border-0 shadow-none py-1 px-2 active"  @click="modal('modal-labeling')" title="Assign label">Multi labeling</a>
                    <a class="btn flex items-center bg-blue-900 p-0 py-1 px-3 font-normal ml-1 text-white" @click="modal('modal-change-set')" title="Data division">Data division</a>
                    <a class="btn flex items-center bg-green-600 p-0 py-1 px-3 font-normal ml-1 text-white" @click="modal('modal-download-selected')">Download</a>
                    <a class="btn flex items-center bg-red-600 p-0 py-1 px-3 font-normal ml-1 text-white" @click="modal('modal-delete')"><TrashIcon class="w-5 h-5 cursor-pointer" /></a>
                    -->
                </div>
              </div> 
            </div>
        </div>
        <!-- IMAGES -->
        <div class="grid chat grid-cols-12 gap-6" :style="layout.dataset?.images?.media && layout.dataset?.images?.media.length ? 'overflow-y: scroll; height: 100%; -ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll;' : ''">
          <!-- loading -->
          <div class="col-span-12 flex flex-col items-center bg-white" v-if="layout.loading">
            <div class="flex items-center justify-center w-full h-full" :style="`background-image: url(${require(`@/assets/images/rosepetal/icon/train7.gif`)}); background-position: top center; background-repeat: no-repeat; height: 100%; height: 100vh;`"></div> >
          </div>
          <!-- list -->
          <div class="col-span-12 text-left text-xs min-h-screen" v-else>
            <div class="intro-y grid grid-cols-12 gap-3 p-5 pb-60" v-if="layout.dataset?.images?.media && layout.dataset?.images?.media.length">
              <div v-for="(m,idx) in layout.dataset?.images?.media" v-bind:key="m" class="intro-y col-span-2" :class="'sm:col-span-'+layout.optImages.mediaSize+' md:col-span-'+layout.optImages.mediaSize+' lg:col-span-'+layout.optImages.mediaSize+' xxl:col-span-'+layout.optImages.mediaSize">
                <div class="file box rounded-md px-2 pb-2 relative"  :class="Object.values(selectedImages).includes(m.id) ? 'border-2  border-blue-600 bg-gray-200' : ''">
                  <div class="intro-y flex items-center h-10 m-0 p-0">
                    <input class="form-check-input border border-gray-900" v-model="selectedImages" :value="m.id" type="checkbox" /> 
                    <div class="w-6 inline-flex ml-auto align-top cursor-pointer" @click="goEditImage(m.id)"><EyeIcon class="w-12 h-12 mr-1" title="View" /></div><!-- layout.edit=false;layout.edit=m.id -->
                    <!--
                    <LayersIcon class="w-5 h-5 cursor-pointer ml-1" />
                    <div class="float-right bg-gray-300 py-1 px-1.5 ml-3 rounded h-6 ml-1 cursor-pointer"><img :src="require(`@/assets/images/rosepetal/icon/pencil.png`)" class="w-3" title="Mask" /></div>
                    -->
                  </div>
                  <div class="block left-0 top-0  items-center cursor-pointer" @click="checkItem(m.id)">
                      <div v-if="m?.comments" class="image-tags pb-3 ml-8"><div class="bg-gray-400 rounded py-0.5" title="Comments"><AlignLeftIcon class="w-4 h-4 mx-1 text-white" title="Comments" /></div></div>
                      <div class="image-tags mt-0.5 pb-3 ml-8" v-if="layout.optImages.mediaLabel" :class="m?.comments ? 'pl-7' : ''">
                        <span class="py-1 px-3 rounded-md cursor-pointer font-medium text-center text-xs text-truncate" :class="!layout.dataset?.tagsCounter?.colors[m.tagName[3]] ? 'bg-gray-300 text-gray-600' : 'text-white'"
                          :style="layout.dataset?.tagsCounter?.colors[m.tagName[3]] ? 'background-color: '+layout.dataset?.tagsCounter?.colors[m.tagName[3]]+' !important' : ''" v-if="m?.tagName && m.tagName[3] && (layout.dataset.type=='MULTICLASS' || layout.dataset.type=='MULTILABEL')">
                          {{ m.tagName[3]=="0" ? 
                             layout.dataset.type=='MULTILABEL' ? 'Anomaly' : 'Unclassified'
                             : layout.dataset?.tagsCounter?.names[m.tagName[3]] }}
                        </span>
                      </div> 
                      <div>
                          <div :id="'ThumbcanvasBox_'+m.id" class="w-full h-full ThumbcanvasBox labelingListCanvas" :style="'width: 100% !important; overflow: hidden !important;'"></div>
                          <div class="image-tags pb-3 pl-3 mt-9" v-if="m.set && layout.optImages.mediaSet">
                            <span class="py-0.5 px-2 rounded-md cursor-pointer font-medium text-center text-xs text-truncate bg-gray-300 text-gray-600 border border-gray-500" >
                              {{ m.set }}
                            </span>
                          </div> 
                          <!--
                          <div class="image-tags pb-3 pl-3" v-if="layout.dataset.type=='imageObjectDetection' && m.set && layout.optImages.mediaSet" :class="layout.optImages.mediaLabel ? ' mt-8' : ' mt-2'">
                            <span class="py-0.5 px-2 rounded-md cursor-pointer font-medium text-center text-xs text-truncate bg-gray-300 text-gray-600 border border-gray-500" >
                              {{ m.set }}
                            </span>
                          </div> 
                          <div class="image-tags pb-3 pl-3" v-if="layout.dataset.type=='MULTICLASS' && m.set && layout.optImages.mediaSet" :class="layout.optImages.mediaLabel ? 'mt-16' : ' mt-8'">
                            <span class="py-0.5 px-2 rounded-md cursor-pointer font-medium text-center text-xs text-truncate bg-gray-300 text-gray-600 border border-gray-500" >
                              {{ m.set }}
                            </span>
                          </div> 
                          -->
                          <div class="image-number pl-3 mt-9 mr-1">
                            <span class="py-1 px-3 rounded-md cursor-pointer font-medium text-right text-xs text-truncate text-white">{{ idx+1 }} </span>
                          </div> 
                          <div class="text-sm font-medium mt-1 ml-1 text-left truncate cursor-default mt-2" v-if="m?.name && layout.optImages.mediaName">{{ m.name.toString().split('/').pop() }}</div>  
                          <div class="text-sm mt-1 ml-1 text-left cursor-default" v-if="m?.createdDate && layout.optImages.mediaDate">{{ m.createdDate }}</div> 
                          <!--<div class="text-xs mt-1 ml-1 text-center cursor-default" v-if="m?.updatedDate && layout.optImages.mediaDate">{{ m.updatedDate }}</div> -->
                      </div>
                  </div>
                </div>     
              </div>
            </div>
            <div v-else class="text-center w-full">
                <div v-if="layout.dataset?.tagsCounter && layout.dataset?.images?.media" class="p-20 bg-white text-center text-gray-600"  style="min-height: 100vh;"> 
                  <div class="flex items-center justify-center w-full h-full bg-white"><img :src="require(`@/assets/images/rosepetal/icon/noResults.jpg`)" /></div>
                  <div>{{ $t('No images found for the selected filters') }}.</div>
                  <div>{{ $t('Please try adjusting your filter criteria') }}.</div>
                </div>
                <div class="col-span-12 lg:col-span-12 flex flex-col items-center" v-else>
                  <div class="flex items-center justify-center w-full h-full bg-white" :style="`background-image: url(${require(`@/assets/images/rosepetal/icon/train7.gif`)}); background-position: top center; background-repeat: no-repeat; height: 100%; height: 100vh;`"></div> 
                </div>
            </div>
            <!-- editor
            <div v-if="layout.edit"><imageEditor :options="{ 'dataset': props.options.dataset, 'id': layout.edit, 'labeling': true }" ref="imageEditorComponent" /></div>
            -->
            <!-- 
            <div v-if="layout.dataset?.images?.dataDivision">
              <pre>{{ layout.dataset?.images?.dataDivision  }}</pre>
            </div>

            <div v-if="layout.dataset?.dataDivision">
              <pre>{{ layout.dataset?.dataDivision  }}</pre>
            </div>

            <div v-if="layout.dataset?.images?.pagination">
              <pre>{{ layout.dataset?.images?.pagination  }}</pre>
            </div>
            -->

          </div>
        </div>  
      </div>
      <div class="col-span-12 sm:col-span-5 lg:col-span-3 xxl:col-span-2 overflow-y-auto bg-white overflow-x-hidden pb-32" :style="'border-left: 1px solid #ddd; -ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll;'">
        <div class="bg-theme-15 editorSaveTools">
          <div class="editorSaveToolsContent">
            <div class="flex flex-wrap sm:flex-row sm:flex-nowrap justify-center items-center mt-0 h-12">
              <button id="BtnLabelingReload" type="button" class="btn w-1/3 hover:bg-green-700 hover:text-white flex items-center text-sm py-3 text-ms editorSaveBtn" 
                :style="'border-radius: 0 !important; font-weight: normal'" @click="reload();">
                <RefreshCwIcon class="w-6 h-5" /> 
              </button>
              <!--
              <button type="button" class="btn w-1/3 hover:bg-green-700 hover:text-white flex items-center text-sm py-3 text-ms editorSaveBtn" 
                :style="'border-radius: 0 !important; font-weight: normal'">
                <MaximizeIcon class="w-6 h-5" /> 
              </button>
              <button type="button" class="btn w-1/3 hover:bg-green-700 hover:text-white flex items-center text-sm py-3 text-ms editorSaveBtn" 
              :style="'border-radius: 0 !important; font-weight: normal'">
                <MonitorIcon class="w-6 h-5" /> 
              </button>
            -->
              <button type="button" data-dismiss="modal" :style="'border-radius: 0 !important'" class="btn text-gray-900 hover:bg-blue-500 hover:text-white flex items-center text-sm py-3 text-base editorSaveBtn w-full" @click="hideLabeling()">
                <Log-outIcon class="w-8 h-5" />
              </button>
            </div>
          </div>
        </div>
        <div class="rpLabelingMenu">
          <ul>
            <li id="eMdataset" class="selected nav" @click="labelingMenu('eMdataset')"><span>{{ $t('Dataset') }}</span><span class="arrow"></span></li>
            <li class="menu-content eMdataset sel">
              <!--<select id="filterDatasetSel" name="filterDatasetSel" class="form-select bg-white cursor-pointer w-full mr-5 text-theme-14" 
                @change=" reload(); goDataset()" v-model="props.options.dataset">
                  <template v-if="props.options.datasets && Object.keys(props.options.datasets).length">
                    <option v-for="(d,idx) in props.options.datasets" :key="idx" :value="d.id">{{ d.id }}</option>
                  </template>
                  <template v-else>
                    <option :value="layout.optImages.datasetID">{{ layout.optImages.datasetID }}</option>
                  </template>
              </select>-->
              <div class="font-medium text-sm" v-if="layout.dataset?.name">{{ layout.dataset.name }}</div>
              <div class="font-normal text-gray-600" v-if="layout.dataset?.typeName">{{ layout.dataset.typeName }}</div>
              <div v-if="layout.dataset?.description" class="pt-2 mr-5 text-gray-600">{{ layout.dataset?.description.toString() }}</div> <!--.substring(0, 170)-->
            </li>

            <!--
            <li id="eMlabelingJobs" class="selected nav" @click="labelingMenu('eMlabelingJobs')"><span>{{ $t('Labeling Jobs') }}</span><span class="arrow"></span></li>
            <li class="menu-content eMlabelingJobs sel">
              <label class="form-label mr-10 mt-2"><button type="button" class="btn w-32 bg-green-600 border-green-900 text-white zoom-in font-normal py-2" @click="modal('modal-labeling-job-box')">{{ $t('Create Job') }}</button></label>
            </li>
            -->
            <template v-if="layout.dataset.type=='imageObjectDetection'">
              <li id="eMobjImages" class="selected nav" @click="labelingMenu('eMobjImages')"><span>{{ $t('Images') }}</span><span class="arrow"></span></li>
              <li class="menu-content eMobjImages sel">
                <div @click="if (!layout.loading) { layout.optImages.objtagsType = 'all'; layout.optImages.objByTag = 'all';  reload(); }" :style="layout.loading ? 'cursor: not-allowed' : ''"
                    class="cursor-pointer border p-2 pb-3 rounded" :class="layout.optImages.objtagsType=='all' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
                  <span class="text-sm inline-block mt-0.5">{{ $t('All images') }}</span> 
                  <div class="py-1 px-2 rounded-full text-xs cursor-pointer font-normal w-24 text-center inline-block float-right" :class="layout.optImages.objtagsType=='all' ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
                    {{ layout.dataset?.tagsCounter?.count && layout.dataset?.tagsCounter?.count!='NaN' 
                       ? this.helper.formatScore(layout.dataset?.tagsCounter?.count)
                       : layout.loading  ? '...' : '0'
                    }}
                  </div>
                </div>
                <div class="mt-2 cursor-pointer border p-2 pb-3 rounded" @click="if (!layout.loading) { layout.optImages.objtagsType = 'labeled'; layout.optImages.objByTag = 'all';reload(); }" :style="layout.loading ? 'cursor: not-allowed' : ''"
                  :class="layout.optImages.objtagsType=='labeled' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
                  <div class="text-sm inline-block mt-0.5">{{ $t('Labeled') }}</div> 
                  <div class="py-1 px-2 rounded-full text-xs cursor-pointer font-normal w-24 text-center inline-block float-right" :class="layout.optImages.objtagsType=='labeled' ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
                    {{ layout.dataset?.tagsCounter?.labeled 
                       ? this.helper.formatScore(layout.dataset?.tagsCounter?.labeled) 
                       : layout.loading  ? '...' : '0'
                    }}<!--{{ layout.dataset?.tagsCounter?.count ? " of " + this.helper.formatScore(layout.dataset?.tagsCounter?.count) : "0" }}-->
                  </div>
                </div>
                <div class="mt-2 cursor-pointer border p-2 pb-3 rounded" @click="if (!layout.loading) { layout.optImages.objtagsType = 'nolabel';layout.optImages.objByTag = 'all'; reload(); }" :style="layout.loading ? 'cursor: not-allowed' : ''"
                  :class="layout.optImages.objtagsType=='nolabel' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
                  <span class="text-sm inline-block mt-0.5">{{ $t('Not labeled') }}</span> 
                  <div class="py-1 px-2 rounded-full text-xs cursor-pointer font-normal w-24 text-center inline-block float-right" :class="layout.optImages.objtagsType=='nolabel' ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-6000'">
                    {{ layout.dataset?.tagsCounter?.nolabel 
                       ? this.helper.formatScore(layout.dataset?.tagsCounter?.nolabel) 
                       : layout.loading  ? '...' : '0'
                    }} <!--{{ layout.dataset?.tagsCounter?.count ? " of " + this.helper.formatScore(layout.dataset?.tagsCounter?.count) : "0" }}-->
                  </div>
                </div>  
              </li>
              <template v-if="(layout.dataset.type=='imageObjectDetection') && layout.dataset?.tagsCounter && layout.optImages.objtagsType=='labeled'">
                <li id="eMobjTags" class="selected nav" @click="labelingMenu('eMobjTags')"><span>{{ $t('Tags') }}</span><span class="arrow"></span></li>
                <li class="menu-content eMobjTags sel">
                  <template v-if="Object.keys(labeledTags).length<=50">
                    <div @click="!layout.loading ? layout.optImages.objByTag = 'all' : '';" class="cursor-pointer border p-3 py-2 rounded mb-2" :style="layout.loading ? 'cursor: not-allowed' : ''"
                      :class="layout.optImages.objByTag=='all' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
                      <span class="text-sm inline-block mt-0.5">{{ $t('All tags') }}</span> 
                    </div>
                    <div @click="!layout.loading ? layout.optImages.objByTag = idx : ''" v-for="(t,idx) in labeledTags" :key="idx" :style="layout.loading ? 'cursor: not-allowed' : ''"
                      class="cursor-pointer border p-2 pb-2.5 rounded mb-2" :class="layout.optImages.objByTag==idx ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
                      <div class="inline-block align-middle mr-2"><div class="mr-1 rounded display-inline roundTagIcon h-5 w-5" :style="layout.dataset.tagsCounter?.colors[idx] ? 'background-color:'+layout.dataset.tagsCounter?.colors[idx]+';border: 2px solid #fff' : ''"></div> </div>
                      <div class="text-sm inline-block mt-1">{{ layout.dataset.tagsCounter?.names[idx] ? layout.dataset.tagsCounter.names[idx] : idx }}</div> 
                      <div class="py-1 px-4 rounded-full text-xs cursor-pointer font-normal w-50 text-center inline-block float-right" :class="layout.optImages.objByTag==idx ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
                        {{ t ? this.helper.formatScore(t) : "0" }} {{ $t('annotations') }}  {{ layout.dataset?.images?.objtagsCount && layout.dataset?.images?.objtagsCount?.tagslabeledImg && layout.dataset?.images?.objtagsCount?.tagslabeledImg[idx] ? 'in ' + layout.dataset?.images?.objtagsCount.tagslabeledImg[idx]  + ' images': '' }}
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <select class="form-select bg-blue-500 text-white cursor-pointer w-full mr-5 " v-model="layout.optImages.objByTag">
                      <option value="all">{{ $t('All tags') }}</option>
                      <option :value="idx" v-for="(t,idx) in labeledTags" :key="idx">
                        {{ layout.dataset.tagsCounter?.names[idx] ? layout.dataset.tagsCounter.names[idx] : idx }} ({{ t }} {{ $t('annotations') }} 
                        {{ layout.dataset?.images?.objtagsCount && layout.dataset?.images?.objtagsCount?.tagslabeledImg && layout.dataset?.images?.objtagsCount?.tagslabeledImg[idx] ? 'in ' + layout.dataset?.images?.objtagsCount.tagslabeledImg[idx] + ' images' : '' }})</option>
                    </select>
                  </template>
                </li>
              </template>
            </template>

            <template v-if="layout.dataset.type=='MULTILABEL'">
              <li id="eMobjImages" class="selected nav" @click="labelingMenu('eMobjImages')"><span>{{ $t('Images') }}</span><span class="arrow"></span></li>
              <li class="menu-content eMobjImages sel">
                <div @click="if (!layout.loading) { layout.optImages.objtagsType = 'all'; layout.optImages.objByTag = 'all';  reload(); }" :style="layout.loading ? 'cursor: not-allowed' : ''"
                    class="cursor-pointer border p-2 pb-3 rounded" :class="layout.optImages.objtagsType=='all' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
                  <span class="text-sm inline-block mt-0.5">{{ $t('All images') }}</span> 
                  <div class="py-1 px-2 rounded-full text-xs cursor-pointer font-normal w-24 text-center inline-block float-right" :class="layout.optImages.objtagsType=='all' ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
                    {{ layout.dataset?.tagsCounter?.count && layout.dataset?.tagsCounter?.count!='NaN' 
                       ? this.helper.formatScore(layout.dataset?.tagsCounter?.count)
                       : layout.loading  ? '...' : '0'
                    }}
                  </div>
                </div>
                <div class="mt-2 cursor-pointer border p-2 pb-3 rounded" @click="!layout.loading ? layout.optImages.objByTag = 'OK' : ''; layout.optImages.objtagsType = 'normal';"
                    :class="layout.optImages.objtagsType=='normal' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
                  <div class="text-sm inline-block mt-0.5">{{ $t('Normal') }}</div> 
                  <div class="py-1 px-2 rounded-full text-xs cursor-pointer font-normal w-24 text-center inline-block float-right bg-gray-300 text-gray-600" >
                    {{ layout.dataset?.tagsCounter?.multi?.normal  }}
                  </div>
                </div>
                <div class="mt-2 cursor-pointer border p-2 pb-3 rounded" @click="if (!layout.loading) { layout.optImages.objtagsType = 'anomaly'; layout.optImages.objByTag = '0'; reload(); }"
                  :class="layout.optImages.objtagsType=='anomaly' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
                  <div class="text-sm inline-block mt-0.5">{{ $t('Anomaly') }}</div> 
                  <div class="py-1 px-2 rounded-full text-xs cursor-pointer font-normal w-24 text-center inline-block float-right bg-gray-300 text-gray-600">
                    {{ layout.dataset?.tagsCounter?.multi?.anomaly  }}
                  </div>
                </div>
              </li>
              
              <template v-if="(layout.dataset.type=='MULTILABEL') && layout.dataset?.tagsCounter && layout.optImages.objtagsType=='anomaly'">
                <li id="eMobjTags" class="selected nav" @click="labelingMenu('eMobjTags')"><span>{{ $t('Tags') }}</span><span class="arrow"></span></li>
                <li class="menu-content eMobjTags sel">
                  <template v-if="Object.keys(labeledTags).length<=50">
                    <div @click="!layout.loading ? layout.optImages.objByTag = '0' : '';" class="cursor-pointer border p-3 py-2 rounded mb-2" :style="layout.loading ? 'cursor: not-allowed' : ''"
                      :class="layout.optImages.objByTag=='0' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
                      <span class="text-sm inline-block mt-0.5">{{ $t('All tags') }}</span> 
                    </div>
                    
                    <div @click="!layout.loading ? layout.optImages.objByTag = idx : ''" v-for="(t,idx) in labeledTags" :key="idx" :style="layout.loading ? 'cursor: not-allowed' : ''"
                      class="cursor-pointer border p-2 pb-2.5 rounded mb-2" :class="layout.optImages.objByTag==idx ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
                      <div class="inline-block align-middle mr-2"><div class="mr-1 rounded display-inline roundTagIcon h-5 w-5" :style="layout.dataset.tagsCounter?.colors[idx] ? 'background-color:'+layout.dataset.tagsCounter?.colors[idx]+';border: 2px solid #fff' : ''"></div> </div>
                      <div class="text-sm inline-block mt-1">{{ layout.dataset.tagsCounter?.names[idx] ? layout.dataset.tagsCounter.names[idx] : idx }}</div> 
                      <div class="py-1 px-4 rounded-full text-xs cursor-pointer font-normal w-50 text-center inline-block float-right" :class="layout.optImages.objByTag==idx ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
                        {{ t ? this.helper.formatScore(t) : "0" }} {{ $t('annotations') }}  {{ layout.dataset?.images?.objtagsCount && layout.dataset?.images?.objtagsCount?.tagslabeledImg && layout.dataset?.images?.objtagsCount?.tagslabeledImg[idx] ? 'in ' + layout.dataset?.images?.objtagsCount.tagslabeledImg[idx]  + ' images': '' }}
                      </div>
                    </div>
                    
                  </template>
                  <template v-else>
                    <select class="form-select bg-blue-500 text-white cursor-pointer w-full mr-5 " v-model="layout.optImages.objByTag">
                      <option value="0">{{ $t('All tags') }}</option>
                      <option :value="idx" v-for="(t,idx) in labeledTags" :key="idx">
                        {{ layout.dataset.tagsCounter?.names[idx] ? layout.dataset.tagsCounter.names[idx] : idx }} ({{ t }} {{ $t('annotations') }} 
                        {{ layout.dataset?.images?.objtagsCount && layout.dataset?.images?.objtagsCount?.tagslabeledImg && layout.dataset?.images?.objtagsCount?.tagslabeledImg[idx] ? 'in ' + layout.dataset?.images?.objtagsCount.tagslabeledImg[idx] + ' images' : '' }})</option>
                    </select>
                  </template>
                </li>
              </template>
              
            </template>

            <template v-if="layout.dataset.type=='MULTICLASS'">
              <li id="eMmultiTags" class="selected nav" @click="labelingMenu('eMmultiTags')"><span>{{ $t('Tags') }}</span><span class="arrow"></span></li>
              <li class="menu-content eMmultiTags sel">
                <template v-if="layout.dataset?.tagsCounter">
                  <template v-if="Object.keys(layout.dataset.tagsCounter.tags).length<=50">
                      <div @click="!layout.loading ? layout.optImages.objByTag = 'all' : '';" 
                        class="cursor-pointer border p-3 py-2 rounded mb-2" :class="layout.optImages.objByTag=='all' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'" :style="layout.loading ? 'cursor: not-allowed' : ''">
                        <span class="text-sm inline-block mt-0.5">{{ $t('All') }} </span> 
                        <div class="py-1 px-4 rounded-full text-xs cursor-pointer font-normal w-28 text-center inline-block float-right" :class="layout.optImages.objByTag==idx ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
                          {{ layout.dataset?.tagsCounter?.count ? this.helper.formatScore(layout.dataset?.tagsCounter?.count) : "0" }}
                        </div>
                      </div>

                      <div @click="!layout.loading ? layout.optImages.objByTag = idx : ''" 
                        v-for="(t,idx) in layout.dataset.tagsCounter.tags" :key="idx"  :style="layout.loading ? 'cursor: not-allowed' : ''"
                        class="cursor-pointer border p-2 pb-2.5 rounded mb-2" :class="layout.optImages.objByTag==idx ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
                        <div class="inline-block align-middle mr-1"><div class="rounded display-inline roundTagIcon h-6 w-6" :style="layout.dataset.tagsCounter?.colors[idx] ? 'background-color:'+layout.dataset.tagsCounter?.colors[idx]+';border: 2px solid #fff' : ''"></div> </div>
                        <div class="text-xs inline-block mt-1">{{ layout.dataset.tagsCounter?.names[idx] ? layout.dataset.tagsCounter.names[idx] : idx }}</div> 
                        <div class="py-1 px-4 rounded-full text-xs cursor-pointer font-normal w-28 text-center inline-block float-right" :class="layout.optImages.objByTag==idx ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
                          {{ t ? this.helper.formatScore(t) : "0" }}
                        </div>
                      </div>
                  </template>
                  <template v-else>
                    <select class="form-select bg-white cursor-pointer w-full mr-5 text-theme-14" v-if="layout.dataset.type=='MULTICLASS'" 
                      v-model="layout.optImages.objByTag" @change="reload()">
                        <option value="all">{{ $t('All') }}  ({{ layout.dataset?.tagsCounter?.count ? this.helper.formatScore(layout.dataset?.tagsCounter?.count) : "0" }})</option>
                        <option :value="idx" v-for="(t,idx) in layout.dataset.tagsCounter.tags" :key="idx">{{ layout.dataset.tagsCounter?.names[idx] ? layout.dataset.tagsCounter.names[idx] : idx }} ({{ t ? this.helper.formatScore(t) : "0" }})</option>
                    </select>
                  </template>
                </template>
                <template v-else>
                  <div class="w-52 mr-5 inline-block"><div class="w-8 mr-5 inline-block"><LoadingIcon icon="three-dots" class="w-8 h-8 text-white" /></div></div>
                </template>
              </li>
            </template>

            <!--<template v-if="layout.dataset.type=='MULTICLASS' || layout.dataset.type=='imageObjectDetection'">-->
              <li id="eMdivision" class="selected nav" @click="labelingMenu('eMdivision')"><span>{{ $t('Data division') }}</span><span class="arrow"></span></li>
              <li class="menu-content eMdivision sel">
                <template v-if="layout.dataset?.tagsCounter">
                  <div @click="!layout.loading ? layout.optImages.division = 'all' : '';" :style="layout.loading ? 'cursor: not-allowed' : ''"
                    class="cursor-pointer border p-3 py-2 rounded mb-2" :class="layout.optImages.division=='all' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
                    <span class="text-sm inline-block mt-0.5">{{ $t('All division') }} </span>
                    <div class="py-1 px-4 rounded-full text-xs cursor-pointer font-normal w-28 text-center inline-block float-right" :class="layout.optImages.division=='all' ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
                      {{ 
                        layout.dataset?.images?.dataDivision?.total ? this.helper.formatScore(layout.dataset?.images?.dataDivision?.total) :  
                        layout.loading  ? '...' : layout.dataset?.dataDivision ? layout.dataset?.images?.dataDivision?.total.toString()!="0" ? this.helper.formatScore(parseInt(layout.dataset?.dataDivision?.train+layout.dataset?.dataDivision?.test+layout.dataset?.dataDivision?.validation+layout.dataset?.dataDivision?.predetermined)) : 0  : 0
                      }}
                    </div>
                  </div>
                  <div @click="!layout.loading ? layout.optImages.division = 'train' : '';" :style="layout.loading ? 'cursor: not-allowed' : ''"
                    class="cursor-pointer border p-3 py-2 rounded mb-2" :class="layout.optImages.division=='train' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
                    <span class="text-sm inline-block mt-0.5">{{ $t('Train') }} </span> 
                    <div class="py-1 px-4 rounded-full text-xs cursor-pointer font-normal w-28 text-center inline-block float-right" :class="layout.optImages.division=='train' ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
                      {{ layout.dataset?.images?.dataDivision?.train ? 
                        this.helper.formatScore(layout.dataset?.images?.dataDivision?.train) /*+ " ("+ ((layout.dataset?.images?.dataDivision?.train/layout.dataset?.images?.dataDivision?.total)*100).toFixed(0) +"%)"*/ 
                        : (layout.dataset.type=='MULTICLASS' && layout.optImages.objByTag=='all') ||  ((layout.dataset.type=='imageObjectDetection' || layout.dataset.type=='MULTILABEL') && layout.optImages.objtagsType=='all') 
                            ?  layout.dataset?.dataDivision && layout.dataset?.dataDivision?.train ? this.helper.formatScore(layout.dataset?.dataDivision?.train) /*+ " ("+ ((layout.dataset?.dataDivision?.train/layout.dataset?.dataDivision?.total)*100).toFixed(0) +"%)*/ : "0" 
                            :  layout.loading  ? '...' : '0'
                      }}
                    </div>
                  </div>
                  <div @click="!layout.loading ? layout.optImages.division = 'test' : '';" :style="layout.loading ? 'cursor: not-allowed' : ''"
                    class="cursor-pointer border p-3 py-2 rounded mb-2" :class="layout.optImages.division=='test' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
                    <span class="text-sm inline-block mt-0.5">{{ $t('Test') }} </span> 
                    <div class="py-1 px-4 rounded-full text-xs cursor-pointer font-normal w-28 text-center inline-block float-right" :class="layout.optImages.division=='test' ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
                      {{ layout.dataset?.images?.dataDivision?.test ? 
                        this.helper.formatScore(layout.dataset?.images?.dataDivision?.test) /*+ " ("+ ((layout.dataset?.images?.dataDivision?.test/layout.dataset?.images?.dataDivision?.total)*100).toFixed(0) +"%)"*/ 
                        : (layout.dataset.type=='MULTICLASS' && layout.optImages.objByTag=='all') ||  ((layout.dataset.type=='imageObjectDetection' || layout.dataset.type=='MULTILABEL') && layout.optImages.objtagsType=='all') 
                            ?  layout.dataset?.dataDivision && layout.dataset?.dataDivision?.test ? this.helper.formatScore(layout.dataset?.dataDivision?.test) /*+ " ("+ ((layout.dataset?.dataDivision?.test/layout.dataset?.dataDivision?.total)*100).toFixed(0) +"%)"*/ : "0" 
                            :  layout.loading  ? '...' : '0'
                      }}
                    </div>
                  </div>
                  <div @click="!layout.loading ? layout.optImages.division = 'validation' : '';" :style="layout.loading ? 'cursor: not-allowed' : ''"
                    class="cursor-pointer border p-3 py-2 rounded mb-2" :class="layout.optImages.division=='validation' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
                    <span class="text-sm inline-block mt-0.5">{{ $t('Validation') }} </span> 
                    <div class="py-1 px-4 rounded-full text-xs cursor-pointer font-normal w-28 text-center inline-block float-right" :class="layout.optImages.division=='validation' ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
                      {{ layout.dataset?.images?.dataDivision?.validation ? 
                        this.helper.formatScore(layout.dataset?.images?.dataDivision?.validation) /*+ " ("+ ((layout.dataset?.images?.dataDivision?.validation/layout.dataset?.images?.dataDivision?.total)*100).toFixed(0) +"%)"*/ 
                        : (layout.dataset.type=='MULTICLASS' && layout.optImages.objByTag=='all') ||  ((layout.dataset.type=='imageObjectDetection' || layout.dataset.type=='MULTILABEL') && layout.optImages.objtagsType=='all') 
                            ?  layout.dataset?.dataDivision && layout.dataset?.dataDivision?.validation ? this.helper.formatScore(layout.dataset?.dataDivision?.validation) /*+ " ("+ ((layout.dataset?.dataDivision?.validation/layout.dataset?.dataDivision?.total)*100).toFixed(0) +"%)"*/ : "0" 
                            :  layout.loading  ? '...' : '0'
                      }}
                    </div>
                  </div>
                  <div @click="!layout.loading ? layout.optImages.division = 'predetermined' : ''" 
                    :style="layout.loading ? 'cursor: not-allowed' : ''" 
                    class="cursor-pointer border p-3 py-2 rounded mb-2" :class="layout.optImages.division=='predetermined' ? 'bg-blue-500 text-white border-blue-400' : 'text-gray-600'">
                    <span class="text-sm inline-block mt-0.5">{{ $t('Predetermined') }} </span> 
                    <div class="py-1 px-4 rounded-full text-xs cursor-pointer font-normal w-28 text-center inline-block float-right" :class="layout.optImages.division=='predetermined' ? 'text-gray-700 bg-white' : 'bg-gray-300 text-gray-600'">
                      {{ layout.dataset?.images?.dataDivision?.predetermined ? 
                        this.helper.formatScore(layout.dataset?.images?.dataDivision?.predetermined) /*+ " (" + ((layout.dataset?.images?.dataDivision?.predetermined/layout.dataset?.images?.dataDivision?.total)*100).toFixed(0) +"%)"*/ 
                        : (layout.dataset.type=='MULTICLASS' && layout.optImages.objByTag=='all') ||  ((layout.dataset.type=='imageObjectDetection' || layout.dataset.type=='MULTILABEL') && layout.optImages.objtagsType=='all') 
                            ?  layout.dataset?.dataDivision && layout.dataset?.dataDivision?.predetermined ? this.helper.formatScore(layout.dataset?.dataDivision?.predetermined) /*+ " ("+ ((layout.dataset?.dataDivision?.predetermined/layout.dataset?.dataDivision?.total)*100).toFixed(0) +"%)"*/ :"0" 
                            :  layout.loading  ? '...' : '0'
                      }}
                    </div>
                  </div>
                  <!--
                  <select class="form-select bg-white cursor-pointer w-full mr-5 text-theme-14" v-model="layout.optImages.division">
                    <option value="all">{{ $t('All division') }}</option>
                    <option value="train">
                      {{ $t('Train') }} {{ layout.dataset?.images?.dataDivision?.train ? ": " + layout.dataset?.images?.dataDivision?.train + " ("+ ((layout.dataset?.images?.dataDivision?.train/layout.dataset?.images?.dataDivision?.total)*100).toFixed(0) +"%)" : "" }}
                      {{ (layout.dataset.type=='MULTICLASS' && layout.optImages.objByTag=='all') ||  (layout.dataset.type=='imageObjectDetection' && layout.optImages.objtagsType=='all') ?  layout.dataset?.dataDivision && layout.dataset?.dataDivision?.train ? ": " + layout.dataset?.dataDivision?.train + " ("+ ((layout.dataset?.dataDivision?.train/layout.dataset?.dataDivision?.total)*100).toFixed(0) +"%)" : "(0)" : "(0)" }}
                    </option>
                    <option value="test">
                      {{ $t('Test') }} {{ layout.dataset?.images?.dataDivision?.test ? ": " + layout.dataset?.images?.dataDivision?.test + " ("+ ((layout.dataset?.images?.dataDivision?.test/layout.dataset?.images?.dataDivision?.total)*100).toFixed(0) +"%)" : "" }}
                      {{ (layout.dataset.type=='MULTICLASS' && layout.optImages.objByTag=='all') ||  (layout.dataset.type=='imageObjectDetection' && layout.optImages.objtagsType=='all') ?  layout.dataset?.dataDivision && layout.dataset?.dataDivision?.test ? ": " + layout.dataset?.dataDivision?.test + " ("+ ((layout.dataset?.dataDivision?.test/layout.dataset?.dataDivision?.total)*100).toFixed(0) +"%)" : "(0)" : "(0)" }}
                    </option>
                    <option value="validation">
                      {{ $t('Validation') }} {{ layout.dataset?.images?.dataDivision?.validation ? ": " + layout.dataset?.images?.dataDivision?.validation + " ("+ ((layout.dataset?.images?.dataDivision?.validation/layout.dataset?.images?.dataDivision?.total)*100).toFixed(0) +"%)" : "" }}
                      {{ (layout.dataset.type=='MULTICLASS' && layout.optImages.objByTag=='all') ||  (layout.dataset.type=='imageObjectDetection' && layout.optImages.objtagsType=='all') ?  layout.dataset?.dataDivision && layout.dataset?.dataDivision?.validation ? ": " + layout.dataset?.dataDivision?.validation + " ("+ ((layout.dataset?.dataDivision?.validation/layout.dataset?.dataDivision?.total)*100).toFixed(0) +"%)" : "(0)" : "(0)" }}
                    </option>
                    <option value="predetermined" :disabled="layout.dataset.type=='imageObjectDetection' ? true: false" :style="layout.dataset.type=='imageObjectDetection' ? 'display: block; color: gray;' : ''"> 
                      {{ $t('Unassigned') }} {{ layout.dataset?.images?.dataDivision?.predetermined ? ": " + layout.dataset?.images?.dataDivision?.predetermined + " (" + ((layout.dataset?.images?.dataDivision?.predetermined/layout.dataset?.images?.dataDivision?.total)*100).toFixed(0) +"%)" : "" }}
                      {{ (layout.dataset.type=='MULTICLASS' && layout.optImages.objByTag=='all') ||  (layout.dataset.type=='imageObjectDetection' && layout.optImages.objtagsType=='all') ?  layout.dataset?.dataDivision && layout.dataset?.dataDivision?.predetermined ? ": " + layout.dataset?.dataDivision?.predetermined + " ("+ ((layout.dataset?.dataDivision?.predetermined/layout.dataset?.dataDivision?.total)*100).toFixed(0) +"%)" : "(0)" : "(0)" }}
                    </option>
                  </select>
                  -->
                </template>
              </li>
            <!--</template>-->

            <!--
            <pre>{{  layout.dataset?.tagsCounter }}</pre>
            <pre>{{  layout.dataset?.dataDivision }}</pre>
            <pre v-if="layout.dataset?.images?.dataDivision">{{  layout.dataset?.images?.dataDivision }}</pre>
            -->

          </ul>
        </div>
      </div>
    </div>

    <!-- MODAL JOB --> 
    <div id="modal-labeling-job-box"  class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-md">
          <div class="modal-content">
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-6 h-6 mt-1 " /></label>
            <div class="modal-body p-5 pt-0 text-left">
                <div class="p-5 pt-4 pl-0 pb-2 text-left text-lg">{{ $t('Labeling Job') }}</div>
                <p class="pb-4">
                  {{ $t('Create a new labeling job for the dataset') }}
                </p>
                <div>
                  <div class="form-check"><label class="form-check-label pr-3 w-32 ml-0">{{ $t('Dataset') }}</label><input type="text" class="form-control" :value="layout.dataset.name" disabled /></div>
                  <div class="form-check mt-5"><label class="form-check-label pr-3 w-32 ml-0">{{ $t(' Job Name') }}</label><input type="text" class="form-control" placeholder="" /></div>
                  <div class="form-check mt-5">
                    <pre class="text-xs">{{ layout.dataset }}</pre>
                  </div>
                </div>
                <div class="px-5 pb-3 text-center mt-1">
                    <button type="button" class="btn btn-outline-secondary w-24 mr-1 h-10 zoom-in" data-dismiss="modal">{{ $t('Cancel') }}</button>
                    <button type="button" class="btn bg-green-500 text-white w-24 mr-1 h-10 zoom-in">{{ $t('Create') }}</button>
                </div>
              </div>
          </div>
      </div>
    </div>

    <!-- MODAL DELETE IMAGES --> 
    <div id="modal-delete" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-body p-5 text-center">
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
            <div class="p-2 text-center">
                <div class="text-xl mt-5">{{ $t('Are you sure you want to delete') }}<br> {{ selectedImages.length }} {{ $t('selected media') }}?</div>
                <div class="text-gray-600 mt-2">{{ $t('This action is irreversible') }}.</div>
            </div>
            <div class="px-5 pb-8 text-center">
                <button id="dismiss-modal-delete" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                <button type="button" class="btn btn-danger w-24" data-dismiss="modal" @click="deleteImages()">{{ $t('Continue') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL DOWNLOAD IMAGES -->  
    <div id="modal-download-selected" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
          <div class="modal-body px-5 text-center">
            <div v-if="layout.zip.loading" class="pb-2">
              <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-5 mb-5"><LoadingIcon icon="ball-triangle" class="w-12 h-12" /></div>
              <div class="text-normal">{{ $t('Creating Zip file') }} ({{layout.zip.counter}}/{{ selectedImages.length }})</div>
              <div class="text-normal pb-5" v-if="selectedImages.length && layout.zip.counter==selectedImages.length">
                  {{ $t('Your download will start automatically') }}
                  <div>{{ $t('Please wait...') }}</div>
              </div>
            </div>
            <div v-else>
              <div class="p-2 text-center">
                  <DownloadCloudIcon class="w-16 h-16 text-theme-1 mx-auto" />
                  <div class="text-2xl ">{{ $t('Download') }}</div>
                  <div class="text-normal">{{ selectedImages.length }} {{ $t('selected media') }}</div>
              </div>
              <div class="px-5 pb-3 text-center mt-5">
                  <button id="dismiss-modal-download-selected" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-2">{{ $t('Cancel') }}</button>
                  <button type="button" @click="downloadMedia()" class="btn btn-primary w-24 rpbtn1">{{ $t('Download') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 

    <!-- MODAL MOVE IMAGES -->  
    <div id="modal-move-selected" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
          <div class="modal-body px-5 text-center">
            <div class="text-2xl text-left">{{ $t('Move images') }}</div>
            <div v-if="layout.move.loading" class="pb-2">
              <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-5 mb-5"><LoadingIcon icon="ball-triangle" class="w-12 h-12" /></div>
              <div class="text-normal">{{ $t('Moving images') }} ({{layout.move.counter}}/{{ selectedImages.length }})</div>
              <div>{{ $t('Please wait...') }}</div>
            </div>
            <div v-else>
              <div v-if="layout.move.success" class="text-left">
                {{ $t('Images have been moved correctly') }}
                 <div class="px-5 pb-3 text-center mt-5">
                      <button type="button" data-dismiss="modal" class="btn btn-primary w-24 rpbtn1">{{ $t('OK') }}</button>
                  </div>
              </div>
              <div v-else class="p-2 text-left">
                  <div class="text-normal"><span class="font-bold">{{ selectedImages.length }}</span> {{ $t('selected media') }}</div>
                  <div class="text-sm truncate mb-3 mt-3 text-center"> {{ $t('Select the destination dataset') }} </div>
                  <select class="form-select bg-white cursor-pointer w-full p-2" v-model="layout.move.toDataset">
                      <option v-for="dataset in actionsDatasets" :value="dataset.id" :key="dataset.id">{{ dataset.name.toString().toLowerCase() }}</option>
                  </select> 
                  <div class="mt-2 text-xs text-center">{{ $t('Images will be placed in the unclassified tag') }}</div>
                  <div class="px-5 pb-3 text-center mt-5">
                      <button id="dismiss-modal-download-selected" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-2">{{ $t('Cancel') }}</button>
                      <button type="button" @click="moveMedia()" class="btn btn-primary w-24 rpbtn1">{{ $t('Move') }}</button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL COPY IMAGES -->  
    <div id="modal-copy-selected" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
          <div class="modal-body px-5 text-center">
            <div v-if="layout.copy.loading" class="pb-2">
              <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-5 mb-5"><LoadingIcon icon="ball-triangle" class="w-12 h-12" /></div>
              <div class="text-normal">{{ $t('Copying images') }} ({{layout.copy.counter}}/{{ selectedImages.length }})</div>
              <div>{{ $t('Please wait...') }}</div>
            </div>
            <div v-else>
              <div class="text-xl text-left">{{ $t('Copy images') }}</div>
              <div v-if="layout.copy.success" class="text-left">
                {{ $t('Images have been copied correctly') }}
                 <div class="px-5 pb-3 text-center mt-5">
                      <button type="button" data-dismiss="modal" class="btn btn-primary w-24 rpbtn1">{{ $t('OK') }}</button>
                  </div>
              </div>
              <div v-else class="p-2 text-left">
                  <div class="text-normal"><span class="font-bold">{{ selectedImages.length }}</span> {{ $t('selected media') }}</div>
                  <div class="text-sm truncate mb-3 mt-3 text-center"> {{ $t('Select the destination dataset') }} </div>
                  <select class="form-select bg-white cursor-pointer w-full p-2" v-model="layout.copy.toDataset">
                      <option v-for="dataset in actionsDatasets" :value="dataset.id" :key="dataset.id">{{ dataset.name.toString().toLowerCase() }}</option>
                  </select> 
                  <div class="mt-2 text-xs text-center">{{ $t('Images will be placed in the unclassified tag') }}</div>
                  <div class="px-5 pb-3 text-center mt-5">
                      <button id="dismiss-modal-download-selected" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-2">{{ $t('Cancel') }}</button>
                      <button type="button" @click="copyMedia()" class="btn btn-primary w-24 rpbtn1">{{ $t('Copy') }}</button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL SELECTED CHANGE SET -->
    <div id="modal-change-set" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"  v-if="!layout.actions.assingLoad"><XIcon class="w-8 h-8" /></label>
          <div class="modal-body p-5 text-center">
            <h2 class="text-2xl font-medium truncate pt-1 pb-1"> {{ $t('Data division') }}</h2>
            <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-5 mb-5" v-if="layout.actions.assingLoad">
              <LoadingIcon icon="ball-triangle" class="w-12 h-12" />
            </div>
            <div v-else>
              <div class="text-lg truncate mb-2"> {{ $t('Selected items') }}: {{countCheck()}}</div>
              <div class="text-xs truncate mb-3"> {{ $t('Select the division you want to assign') }} </div>
              <select class="form-select bg-white cursor-pointer w-52 p-2" v-model="layout.actions.assingSet">
                  <option value="train">{{ $t('Train') }}</option>
                  <option value="test">{{ $t('Test') }}</option>
                  <option value="validation">{{ $t('Validation') }}</option>
                  <option value="predetermined">{{ $t('Predetermined') }}</option>
              </select> 
              <div class="text-left text-xs bg-gray-200 rounded p-5 m-5">
                <div class="text-xl"><img class="w-5 h-5 inline-block mr-1.5 align-top mt-1" :src="require(`@/assets/images/rosepetal/icon/info.png`)" />  {{ $t('About data division') }}</div>
                <div class="mt-3"><div class="font-medium text-sm">{{ $t('Train') }}</div>{{ $t('Used to adjust the model parameters') }}.</div>
                <div class="mt-2"><div class="font-medium text-sm">{{ $t('Test') }}</div>{{ $t('Used to evaluate the final performance of the model on unseen data and prevent overfitting') }}.</div>
                <div class="mt-2 mb-3"><div class="font-medium text-sm">{{ $t('Validation') }}</div>{{ $t('Used to evaluate the model performance during training and make adjustments to the model') }}.</div>
             </div>
              <div class="px-5 pb-3 text-center mt-5">
                  <button id="dismiss-modal-labeling" type="button"
                  data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                  <button type="button" class="btn btn-primary w-24" v-if="layout.actions.assingSet" @click="setDataDivision()">{{ $t('Confirm') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL LABEL IMAGES CLASSIFICATION -->
    <div id="modal-labeling" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
          <div class="modal-body p-5 text-center">
            <h2 class="text-2xl font-medium truncate pt-1 pb-1"> {{ $t('Tag assignment') }}</h2>
            <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-5 mb-5" v-if="layout.actions.assingLoad">
              <LoadingIcon icon="ball-triangle" class="w-12 h-12" />
            </div>
            <div v-else>
              <div class="text-lg truncate mb-2"> {{ $t('Selected items') }}: {{countCheck()}}</div>
              <div class="text-xs truncate mb-1"> {{ $t('Select the tag you want to assign') }}: </div>
              <div class="pt-3 pb-5 text-left" v-if="layout.dataset.tagsCounter">
                  <div v-for="(t,idx) in layout.dataset.tagsCounter.tags" :key="idx">  
                    <button class="btn mb-1 hover:bg-blue-500 hover:text-white w-full text-left font-normal" @click="layout.actions.assingTag=idx"
                      :class="layout.actions.assingTag===idx? 'bg-blue-500 text-white' : 'text-gray-600'">
                      <div class="float-left w-12"><div class="h-8 w-8 rounded display-inline" :style="layout.dataset.tagsCounter.colors[idx] ? 'background-color:'+layout.dataset.tagsCounter.colors[idx]+';border: 2px solid #fff' : ''"></div> </div>
                      <div class="float-right w-80 ml-1 text-left text-sm"><span class="text-left">{{ layout.dataset.tagsCounter.names[idx] }} <span class="float-right ">{{ t }}</span></span></div>
                    </button>
                  </div>
              </div> 
              <div class="px-5 pb-3 text-center">
                  <button id="dismiss-modal-labeling" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                  <button type="button" class="btn bg-green-600 text-white w-32" @click="categorize()">{{ $t('Confirm') }}</button>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import { defineComponent, onMounted, ref, watch, onUnmounted } from "vue"; 
import * as rosepetalModel from "rosepetal-model";
import { useRouter } from "vue-router";
import { helper as $h } from "@/utils/helper";
//import imageEditor  from "@/components/manage-image/Main.vue";
import cash from "cash-dom";
import { fabric } from 'fabric'
import lzstring from 'lz-string';
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import config from '@/etc/rosepetal.json';

export default defineComponent({
  props: {
      options: {
          type: Object,
          required: true
      },
  },
  components: {
    //imageEditor,
  },
  created () {
    this.helper = rosepetalModel.helper
  },
  computed: {
    labeledTags() { 
      const tags = {};
      for (const key in this.layout.dataset.tagsCounter.tagslabeled) { if (this.layout.dataset.tagsCounter.tagslabeled[key])tags[key] = this.layout.dataset.tagsCounter.tagslabeled[key] }
      return tags;
     },
    noLabelTags() { 
      const tags = {};
      for (const key in this.layout.dataset.tagsCounter.tagslabeled) { if (!this.layout.dataset.tagsCounter.tagslabeled[key])tags[key] = this.layout.dataset.tagsCounter.tagslabeled[key] }
      return tags;
    },
    labeledTagsCount(){ let total = 0; for (const key in this.layout.dataset.tagsCounter.tagslabeled)total += parseInt(this.layout.dataset.tagsCounter.tagslabeled[key]); return total; },
    actionsDatasets() {
        return this.layout.datasets.filter(dataset => dataset.id !== this.layout.dataset.id);
    }
  },
  setup(props) {

    const router   = useRouter();
    const tableRef = ref()
    const layout = ref({
          dataset:      false,
          datasets:     [],
          edit:         false,
          loading:      true,
          tagsListener: false,
          pagination:   false,
          optImages:    { 
                        datasetID:     props.options.dataset, 
                        objtagsType:  'all', 
                        objByTag:     'all', 
                        division:     'all',
                        mediaSize:     window.innerWidth<900 ? 3 : 2,
                        windowWidth:   window.innerWidth,
                        mediaLabel:    true,
                        mediaName:     true, 
                        mediaMask:     false,  
                        mediaSet:      true,
                        mediaDate:     true,
                        maskOpacity:   0.5,
                        //info:          false,
                        perPage:       50,
                        order:         'created'
                       },
          zip:  { loading: false, counter: 0 },
          move: { loading: false, toDataset: "", counter: 0 },
          copy: { loading: false, toDataset: "", counter: 0 },
          actions: { assingLoad: false, assingSet: false, assingTag: false }
    });
    const selectedImages  = ref([]); 
    const selectToDataset = ref([]);  

    const modal       = (m) => { cash("#"+m).modal("show"); cash(".main").removeClass("overflow-y-hidden")};
    const modalClose  = (m) => { cash("#"+m).modal("hide"); };  

    const checkItem  = async (s) => {
      let exists = false
      selectedImages.value.findIndex(function(m, index) { if(m == s){ exists = true; if (index > -1)selectedImages.value.splice(index, 1); } });
      if(!exists)selectedImages.value.push(s) 
    }
    const check       = () => { selectedImages.value = [];  for(let i in layout.value.dataset.images.media){  selectedImages.value.push(layout.value.dataset.images.media[i].id); } }
    const unCheck     = () => { selectedImages.value = [];  }
    const countCheck  = () => { return selectedImages.value.length ? selectedImages.value.length : 0 }
    
    const setDataDivision = async () => { 
      layout.value.actions.assingLoad = true
      let cnt = 0;
      for (const s of selectedImages.value) {
          await rosepetalModel.image.setSet(s, layout.value.actions.assingSet.toString().toUpperCase())
          cnt++;  
      }  
      setTimeout(async () => { 
        let currentSelectedImages = selectedImages.value
        $h.NotificationTxt({ text: cnt +" media set updated" });
        modalClose("modal-change-set")
        layout.value.actions.assingSet = false
        layout.value.actions.assingLoad = false
        await loadImages()
        selectedImages.value = currentSelectedImages
        }, 1000);
    }

    const categorize = async () => { 
      layout.value.actions.assingLoad = true
      let cnt = 0;
      for (const s of selectedImages.value) {
          await rosepetalModel.image.setTag(s, props.options.dataset, layout.value.actions.assingTag)
          cnt++;  
      }  
      setTimeout(async () => { 
        $h.NotificationTxt({ text: cnt +" media tag updated" });
        modalClose("modal-labeling")
        layout.value.actions.assingTag  = false
        layout.value.actions.assingLoad = false
        await loadImages()
        }, 2000);
      /*
      actions.value.assingLoad = true
      let cnt = 0;
      for (const s of selectedImages.value) {
          let uTag = { id: s , datasetID: curDataset.value.id  , tagID: actions.value.assingTag }
          await $image.updateTag(uTag); cnt++;  
      }  
      let txt = msg ? msg: ""
      $h.NotificationTxt({ text: cnt +" "+txt });
      cash("#modal-labeling").modal("hide");
      let curDset             = curDataset.value.id;
      Layout.value.loading    = true
      actions.value.assingTag = curDataset.value  = false
      datasets.value          = media.value       = selectedImages.value = []
      setTimeout(async () => { 
        pagination.value.currentPage = 0
        actions.value.assingLoad = false
        await LoadDatasets(); 
        await Filter({ target: { id: 'filterDatasetSel', value: curDset, sameCat: true }}) 
        }, 2500);*/
    }

    const load = async () => { 
      //layout.value.loading   = true; 
      //calc miliseconds load call
      let start = new Date().getTime(); console.log('INIT load', start)
      
      layout.value.dataset    = await rosepetalModel.dataset.get(props.options.dataset);     console.log('----------- dataset', (new Date().getTime() - start) + "ms" )

      let start2 = new Date().getTime();

      
      layout.value.dataset.dataDivision  = await rosepetalModel.dataset.getDataDivision(props.options.dataset); 
      console.log('----------- dataDivision', (new Date().getTime() - start2) + "ms" )
   
      let start3 = new Date().getTime();

      await loadImages();  console.log('----------- load images', (new Date().getTime() - start3) + "ms" )

      let start4 = new Date().getTime();              
      console.log('----------- init tagsCounter')
      layout.value.dataset.tagsCounter       = await rosepetalModel.dataset.getTagsCounter(props.options.dataset, true); 
      console.log('----------- load tagsCounter', (new Date().getTime() - start4) + "ms" )
      
      console.log('END LOAD', (new Date().getTime() - start) + "ms" )

      if (layout.value.tagsListener) {
        layout.value.tagsListener(); 
        layout.value.tagsListener = null; 
      }
      if(props.options.dataset)
          layout.value.tagsListener  = rosepetalModel._firebase.firestore().collection('dataset').doc(props.options.dataset).collection("tag").onSnapshot(async () => {
                    layout.value.dataset.tagsCounter   = await rosepetalModel.dataset.getTagsCounter(props.options.dataset, true)
                    let _onlyLabeled = false
                    if(layout.value.optImages.objtagsType=='labeled')_onlyLabeled = true
                    let _byTag       = false
                    //if(layout.value.dataset.type=='MULTILABEL')_byTag  = layout.value.optImages.objByTag
                    layout.value.dataset.dataDivision  = await rosepetalModel.dataset.getDataDivision(props.options.dataset, _onlyLabeled, _byTag)
                    })

      layout.value.datasets  = await rosepetalModel.dataset.list()
    }

    const loadImages = async () => { 
      selectedImages.value = []
      let opt = { 
                  datasetID:   props.options.dataset,
                  dataset:     layout.value.dataset,
                  objDivision: layout.value.optImages.division!='all'    ? layout.value.optImages.division    : 'all',
                  objByTag:    layout.value.optImages.objByTag!='all'    ? layout.value.optImages.objByTag    : 'all',
                  perPage:     layout.value.optImages.perPage,
                  //order:       { order: "date", type: "desc"  }, //layout.value.optImages.order=='updated' ? { order: "updatedAt", type: "desc"  } : { order: "date", type: "desc"  },
                  action:      layout.value.optImages.action ? layout.value.optImages.action : false  
                }
      if(layout.value.dataset.type=='imageObjectDetection' || layout.value.dataset.type=='MULTILABEL')opt.objtagsType = layout.value.optImages.objtagsType? layout.value.optImages.objtagsType : 'all'
      if(layout.value.pagination)opt.paginationQry = layout.value.pagination
      layout.value.dataset.images            = await rosepetalModel.dataset.getImages(opt)
      layout.value.pagination                = layout.value.dataset.images.pagination ? layout.value.dataset.images.pagination : false
      await loadThumb()
      cash(".wrapper-box .content").addClass("singlemode_wrapper")
      layout.value.loading                   = false
    }

    const loadThumb = async () => { setTimeout(async () => {  for(let i in layout.value.dataset.images.media){ if(layout.value.dataset.images.media[i])await thumb(layout.value.dataset.images.media[i]) } }, 5); }

    const thumb = async (image) => { 
      if(cash("#ThumbcanvasBox_"+image.id)){
        cash("#ThumbcanvasBox_"+image.id).html('<canvas id="thumbcanvas_'+image.id+'" class="thmbLabeling"></canvas>')
        let thumbCanvas = new fabric.Canvas('thumbcanvas_'+image.id, {selection: false, defaultCursor: 'pointer'});
        fabric.Image.fromURL('data:image/png;base64,'+image.img_base64_val, async function (img) { 
            thumbCanvas.setHeight(img.height); 
            thumbCanvas.setWidth(img.width);
            //load background image
            thumbCanvas.setBackgroundImage(img, thumbCanvas.renderAll.bind(thumbCanvas), { scaleX: thumbCanvas.width / img.width, scaleY: thumbCanvas.height / img.height });
            thumbCanvas.renderAll();
            //load mask json
            if(layout.value.optImages.mediaMask && image?.mask?.imageJson){
              var json = false //JSON.parse(image.mask.imageJson)
              try { json = JSON.parse(image.mask.imageJson)
              } catch (error) {  //Compressed with lz-string
                json = JSON.parse(lzstring.decompressFromUint8Array(image.mask.imageJson.toUint8Array())) ;
              }
              thumbCanvas.loadFromJSON(json, function() { thumbCanvas.setBackgroundImage(img, thumbCanvas.renderAll.bind(thumbCanvas), { scaleX: thumbCanvas.width / img.width, scaleY: thumbCanvas.height / img.height }); thumbCanvas.renderAll(); }, 
                function(o, object) {
                  object.selectable = false;
                  var widthRatio    = thumbCanvas.width / image?.mask?.width;
                  var heightRatio   = thumbCanvas.height / image?.mask?.height;
                  var scaleFactor   = Math.min(widthRatio, heightRatio);
                  object.set({
                    strokeWidth: object.strokeWidth * scaleFactor,
                    scaleX: object.scaleX * scaleFactor,
                    scaleY: object.scaleY * scaleFactor,
                    left: object.left * scaleFactor,
                    top: object.top * scaleFactor,
                    opacity: layout.value.optImages.maskOpacity,
                    selectable: false,
                    hoverCursor: "default"
                  });
                  thumbCanvas.renderAll();
              });
            }      
            //load boundingBox
            if(layout.value.optImages.mediaLabel && image.tags){
                for(let index in image.tags){ 
                    let savedObject = image.tags[index] 
                    if(savedObject.type=="rect"){
                      let RectOpt = {
                          left:     savedObject.x * img.width,
                          top:      savedObject.y * img.height,
                          originX:  'left',
                          originY:  'top',
                          width:    savedObject.w*img.width,
                          height:   savedObject.h*img.height,
                          angle:    0,
                          fill:     'transparent',
                          strokeWidth: 1,
                          transparentCorners: false,
                          hoverCursor: "default"
                      }
                      RectOpt.selectable    = false;   
                      let newObject         = new fabric.Rect(RectOpt);
                      let tagName           = savedObject.tag.path.toString().split('/').pop()
                      let _tagData          = await rosepetalModel.dataset.getTag(layout.value.dataset.id, tagName)
                      if (tagName) {
                        let bbColor = _tagData.color ? _tagData.color : 'ff0000'
                        let newObjectId    = index+tagName;
                        newObject.toObject = (function(toObject) {
                          return function(propertiesToInclude) {
                            return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
                              name: tagName,
                              objid: newObjectId
                            });
                          };
                        })(newObject.toObject); 
                        newObject.set({stroke: bbColor })
                        newObject.set({name: tagName })
                        newObject.set({objid: newObjectId })
                        thumbCanvas.add(newObject);
                        let TextOpt = {
                                fill: bbColor,
                                left: savedObject.x * img.width,
                                top: ((savedObject.y * img.height)-28),
                                fontSize: 20,
                                padding: 5,
                                objParentId: newObjectId,
                                hoverCursor: "default"
                            }
                        TextOpt.selectable = false;   
                        var tagNameBox = new fabric.Text(tagName, TextOpt);
                        tagNameBox.toObject = (function(toObject) {
                          return function(propertiesToInclude) {
                            return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
                              objParentId: newObjectId
                            });
                          };
                        })(newObject.toObject);     
                        tagNameBox.set({objParentId: newObjectId })
                        thumbCanvas.add(tagNameBox);
                      }
                    }
                }
            }
            //change canvas height adjust image
            thumbSize(img)
        });
        thumbCanvas.renderAll();
      }
    }

    const thumbSize = async (img) => { 
      if(document.getElementsByClassName("labelingListCanvas")[0]){
        let newWidth         = document.getElementsByClassName("labelingListCanvas")[0].offsetWidth
        let newHeight        = (img.height / img.width) * newWidth
        let mosaicCanvases   = document.getElementsByClassName("labelingListCanvas")
        let canvasContainers = [];
        for (var i = 0; i < mosaicCanvases.length; i++) {  canvasContainers = canvasContainers.concat(Array.from(mosaicCanvases[i].getElementsByClassName("canvas-container"))); }
        if(canvasContainers.length)for(i = 0; i < canvasContainers.length; i++) { canvasContainers[i].style.height = newHeight+"px"; }
      }
    }

    const deleteImages = async () => { 
      cash("#modal-delete").modal("hide")
      layout.value.loading = true
      resetPagination()
      rosepetalModel.dataset.deleteImagesBatch(props.options.dataset , JSON.stringify(selectedImages.value)).then(async () => {
       $h.NotificationTxt({ text: "Images successfully removed", position: "right" });
       await load()
      })
    }

    const getSelectedImages = async () => {
      
      selectToDataset.value = [];
      const selectedImageIds = new Set(selectedImages.value);
      if(!Object.keys(layout.value.dataset.images.media).length) return;

      const imagePromises = []; 

      for (const s of layout.value.dataset.images.media) {
        if(s && s.id && selectedImageIds && selectedImageIds.has(s.id)) {
            layout.value.zip.counter++;
            var filename    = s.name.substr(s.name.lastIndexOf("/") + 1);

            const imagePromise = await rosepetalModel.image.getStorageUrl(s.uri)
              .then(imageUrl => fetch(imageUrl.url))
              .then(response => response.blob())
              .then(imageBlob => {
                selectToDataset.value.push({name: filename, tag: s.tag, file: imageBlob});
              });

            imagePromises.push(imagePromise);  
            //const imageUrl  = await rosepetalModel.image.getStorageUrl(s.uri)
            //const imageBlob = fetch(imageUrl.url).then(response => response.blob());
            //selectToDataset.value.push({name: filename, tag: s.tag, file: imageBlob});
            //const fetchImageTask = rosepetalModel.image.getStorageUrl(s.uri).then(imageUrl => fetch(imageUrl.url))
                                                                          // .then(response => response.blob())
                                                                          // .then(imageBlob => {
                                                                          //    selectToDataset.value.push({ name: filename, tag: s.tag, file: imageBlob });
                                                                          // })
        }
      }
      
      try {
          await Promise.all(imagePromises);
      } catch (error) {
          console.error("An error occurred:", error);
      }
      /*
      selectToDataset.value = []
      await layout.value.dataset.images.media.reduce(async (a, s) => {
        await a;
        for (const si of selectedImages.value) {
          if(si==s.id){
            layout.value.zip.counter++
            var filename    = s.name.substr(s.name.lastIndexOf("/")+1);
            const imageUrl  = await rosepetalModel.image.getStorageUrl(s.uri);
            const imageBlob = fetch(imageUrl.url).then(response => response.blob());
            selectToDataset.value.push({name: filename, tag: s.tag, file: imageBlob});
          } 
        }  
      }, Promise.resolve());
      */
    }

    const reload = async () => { 
      if(props.options.dataset){
        layout.value.loading   = true; 
        await loadImages()
      }
    }
    
    const resetPagination = async () => { 
      layout.value.pagination       = false; 
      layout.value.optImages.action = false;
      layout.value.dataset.images   = {}
    }

    const labelingMenu = (navId) => {
      let menuItem = document.querySelector('#' + navId);
      if(menuItem.classList.contains('selected')){
        menuItem.classList.remove('selected');
        if (menuItem.id && document.querySelector('.' + menuItem.id) && document.querySelector('.' + menuItem.id).classList.contains('sel')) {
          document.querySelector('.' + menuItem.id).classList.remove('sel');
        }
      }else{
        menuItem.classList.add('selected');
        if (menuItem.id && document.querySelector('.' + menuItem.id)) {
          document.querySelector('.' + menuItem.id).classList.add('sel');
        }
      }
    }

    onMounted( async () => {
      router.push('/datasets/'+props.options.dataset+'/labeling'); 
      cash("#rpTopBarLine").hide()
      if(props.options.dataset){
        await load()
      }
      cash("body").css("overflow-y", "hidden");
    });

    onUnmounted( async () => { cash("body").css("overflow-y", "scroll");  cash(".wrapper-box .content").removeClass("singlemode_wrapper"); cash("#rpTopBarLine").show() }); 

   //window.addEventListener("resize", loadThumb);
    
    watch(() => props.options.dataset, async () => {
      layout.value.edit                  = false
      layout.value.optImages.objtagsType =  'all'
      layout.value.optImages.objByTag    =  'all'
      layout.value.optImages.division    =  'all'
      resetPagination(); 
      await load();  
    })

    watch(() => layout.value.optImages.mediaSize, async () => { loadThumb(); })

    /*watch(() => layout.value.edit, async () => { 
      alert('editando'+layout.value.edit) 
      this goEditImage(layout.value.edit)
      layout.value.item = await rosepetalModel.dataset.getTag(props.options.dataset,layout.value.edit) 
    })*/


    watch(() => layout.value.optImages.objByTag , async () => { 
      layout.value.loading = true
      if(layout.value.dataset.type=='MULTICLASS')layout.value.optImages.division='all'
      resetPagination()
      await loadImages()
    })

    watch(() => layout.value.optImages.division , async () => {
      layout.value.loading = true 
      resetPagination()
      await loadImages()
    })

    watch(() => layout.value.optImages.perPage, async () => {  
      resetPagination()
      await loadImages() 
    })

    watch(() => layout.value.optImages.order, async () => {  
      resetPagination()
      await loadImages() 
    })

    watch(() => layout.value.optImages.maskOpacity, async () => {  
      resetPagination()
      await loadImages() 
    })

    const linkToLabeling = () => { router.push({ name: 'side-menu-data', params: { datasetID: props.options.dataset } }); };
   
    return {
        layout,
        router,
        props,
        tableRef,
        load,
        reload,
        modal,
        modalClose,
        selectedImages,
        selectToDataset,
        getSelectedImages,
        checkItem,
        resetPagination,
        check,
        unCheck,
        countCheck,
        linkToLabeling,
        setDataDivision,
        categorize,
        loadImages,
        labelingMenu,
        deleteImages
    };
  },
  methods: {
    back: async function () {this.$parent.back() },
    goEditImage: async function (i) {this.$parent.goEditImage(i) },
    hideLabeling: async function () { this.router.push('/datasets/'+this.props.options.dataset); this.$parent.hideLabeling(); },
    goDataset: async function () { 
      if(this.props.options.dataset){
        if(this.props.options.datasets){
          for(let ds of this.props.options.datasets){
            if(ds.id==this.props.options.dataset){
              this.$parent.goDataset(ds)
            }
          }
        }
      }
    },
    refreshLabeling: async function () { console.log("refreshLabeling");  await this.load() },
    moveMedia: async function () { 
      if(!this.layout.move.toDataset)alert('Select a dataset to move the images')
      else{
        this.layout.move.loading = true;
        this.layout.move.counter = 0;
        let imagesMoveList       = { images: {} }
        for (let i = 0; i < this.selectedImages.length; i++) {
          if(this.selectedImages[i]){
            this.layout.move.counter++;
            imagesMoveList.images[this.selectedImages[i].toString().replace(/'/g,"")] = "0"
          }
        }
        console.log('move images', imagesMoveList ,'to dataset: '+this.layout.move.toDataset)
        if(Object.keys(imagesMoveList.images).length)await rosepetalModel.dataset.createMoveOperation(this.layout.move.toDataset ,{ images: imagesMoveList.images }, config.functions.usapi)
        this.layout.move.success   = true;
        this.layout.move.loading   = false;
        this.resetPagination()
        await this.loadImages()
      }
    },
    copyMedia: async function () { 
      if(!this.layout.copy.toDataset)alert('Select a dataset to copy the images')
      else{
        this.layout.copy.loading = true;
        this.layout.copy.counter = 0;
        let imagesCopyList       = { images: {} }
        for (let i = 0; i < this.selectedImages.length; i++) {
          if(this.selectedImages[i]){
            this.layout.copy.counter++;
            imagesCopyList.images[this.selectedImages[i].toString().replace(/'/g,"")] = "0"
          }
        }
        if(Object.keys(imagesCopyList.images).length)await rosepetalModel.dataset.createCopyOperation(this.layout.copy.toDataset ,{ images: imagesCopyList.images }, config.functions.usapi)
        this.layout.copy.success   = true;
        this.layout.copy.counter   = 0;
        this.layout.copy.loading   = false;
      }
    },
    downloadMedia: async function(){ 
      this.layout.zip.loading = true;
      this.layout.zip.counter = 0;
      await this.getSelectedImages()
      
      var zip           = new JSZip()  
      var nowdate       = new Date()
      var filename      = this.props.options.dataset+"_("+this.selectToDataset.length+")_"+nowdate.getTime()
      var zipFilename   = filename+".zip";
      
      //create default tags
      let DatasetTags  = await rosepetalModel.dataset.getTags(this.props.options.dataset)
      let folders      = []
      if(Object.keys(DatasetTags).length){
        for (const k of Object.keys(DatasetTags)){ 
          folders[DatasetTags[k].id] = zip.folder(DatasetTags[k].id);
        }
      }
      //insert folder images
      for (let i = 0; i < this.selectToDataset.length; i++) {
        let imgTagID =  this.selectToDataset[i].tag.substring(this.selectToDataset[i].tag.lastIndexOf('/') + 1)
        if(folders[imgTagID]) {
          folders[imgTagID].file(this.selectToDataset[i].name, this.selectToDataset[i].file, { base64: true }) 
        }
      }
      //download zip
      await zip.generateAsync({type:"blob"}).then(async function (blob) {
          saveAs(blob,zipFilename);
          cash("#modal-download-selected").modal("hide");
      });
      //hide modal
      
      this.layout.zip.loading = false;
     },
  }
});
</script>
<style>

#rpFiltersLabeling .form-check-input:checked {
  background-color: #4CAF50; /* Cambia el color de fondo cuando el checkbox está seleccionado */
  border-color: #4CAF50; /* Cambia el color del borde cuando el checkbox está seleccionado */
}

.rpLabelingTopMenu{z-index: 99999999 !important; margin-top: 3px !important;}
.rpLabelingMenu {  width: 100%;font-size: 13px; }
.rpLabelingMenu li { background-color: #f1f5f8; width: 100%; cursor: pointer; padding: 10px; margin-bottom: 1px;display: block; }
.rpLabelingMenu ul li:hover { background-color: #f1f5f8 }
.rpLabelingMenu ul li.selected { background-color: #f1f5f8;}
.rpLabelingMenu ul li .arrow {display: block;float: right;width: 0;height: 0;border-top: 8px solid transparent;border-bottom: 7px solid transparent;border-left: 7px solid #ccc; margin: 2px 8px 0 0;}
.rpLabelingMenu ul li.selected .arrow{ transform: rotate(90deg);}
.rpLabelingMenu ul li.menu-content { background-color: #fff; padding: 15px; font-size: 12px; display: none; cursor: default;}
.rpLabelingMenu ul li.menu-content.sel{ display: block; }
</style>

