<template>
  <div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-3 xxl:col-span-2 flex lg:block flex-col-reverse">
          <div class="intro-y box lg:mt-0 pb-5">
             <div class="relative flex items-center p-5">
              <div class="font-medium text-base"><BoxIcon class="w-6 h-6 mr-1"  />  {{ $t('App') }}</div>
              <div class="absolute right-0 top-0 mr-5 mt-5" v-if="root">
                <PlusCircleIcon class="w-6 h-6 text-gray-600 dark:text-gray-300 cursor-pointer" title="Nuevo Bloque" @click="newView(true)" />
              </div>
            </div>
            <div class="border-t border-gray-200 dark:border-dark-5 pt-2">
             <!-- Create View --> 
             <div class="pb-2 px-3" v-if="createView">
                <form ref="newViEW" class="validate-form" @submit.prevent="addView('newViEW',$event)">
                  <input type="text" name="view" class="form-control" placeholder="Nombre" required  />
                  <button type="submit" class="btn btn-sm btn-secondary w-full mt-2">{{ $t('Add Block') }}</button>
                </form>
             </div>
             <!-- View menu --> 
             <div v-for="(tab) in Tabs" :key="tab.id">
                <a class="flex items-center  cursor-pointer py-2 px-5 border-b border-gray-200 dark:border-dark-5" :class="tab.params.role ? User.role && tab.params.role.includes(User.role.role_id) ? TabActive(tab.id) : 'hidden' : TabActive(tab.id)" 
                   @click="ChangeTab(tab.id)" v-if="Object.keys(tab.params).length !== 0">
                   <component :is="tab.icon" class="w-4 h-4 mr-2" /> {{ tab.name }}
                </a>
              </div>
            </div>
          </div>
      </div>
      <div class="col-span-12 lg:col-span-9 xxl:col-span-8">
        <!-- View attributes -->
        <div v-for="(tab) in Tabs" :key="tab.id">
          <div class="intro-y box" v-if="CurTab==tab.id">
            <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base mr-auto"><component :is="tab.icon" class="w-6 h-6 mr-2"  /> {{ tab.name }}</h2>
            <div class="dropdown ml-auto" v-if="root">
              <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"><MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300" /></a>
              <div class="dropdown-menu w-52">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <a @click="showModal('modalAttr'+tab.id)" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer">
                    <PlusIcon class="w-4 h-4 mr-2" /> {{ $t('New attribute') }}
                  </a>
                  <a @click="showModal('modaldelView'+tab.id)" class="flex items-center block p-2 transition duration-300 bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer">
                    <TrashIcon class="w-4 h-4 mr-2" /> {{ $t('Delete Block') }}
                  </a>
                </div>
              </div>
            </div>
            </div>
            <div id="form-validation" class="p-5">
              <div class="preview">
                  <form :id="tab.id" class="validate-form" @submit.prevent="updateView(tab.id,$event)" :ref="'form-'+tab.id">
                    <div v-for="(param, key) in tab.params" :key="param">
                        <!-- Map -->  
                        <div v-if="this.helper.isObject(param)">
                          <!-- Map > Array -->  
                          <div v-if="Object.entries(param)[0] && this.helper.isObject(Object.entries(param)[0][1])">
                            <div v-for="(p, k) in param" :key="p">  
                              <div v-if="this.helper.isObject(p)">
                                <div class="form-inline mb-2">  
                                  <label class="form-label w-40 font-medium">{{ key }} / {{ k }} <span class="hidden">{{ setOptions(key) }}</span></label>
                                  <div class="input-form mb-3 w-full">
                                    <TailSelect :id="key" :name="k"
                                      ref="TailSelectRef" :options="{ search: true,descriptions: true,hideSelected: true, hideDisabled: true,multiSelectAll: true,multiShowCount: true,multiContainer: true,locale: 'es',classNames: 'w-full',}"
                                      multiple >
                                      <option :value="pp" v-for="(pp) in p" :key="pp" selected>{{ pp }}</option>
                                      <option :value="o.id" v-for="(o) in selOptions" :key="o.id">{{ o.title }}</option>
                                    </TailSelect>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else> 
                            <!-- Array -->  
                            <div> 
                              <div class="form-inline mb-2">  
                                  <label class="form-label w-40 font-medium">{{ key }}</label>
                                  <div class="grid grid-cols-12 gap-2">
                                      <div class="col-span-12" v-for="(p, k) in param" :key="p">
                                        <div class="grid grid-cols-12 gap-2">
                                            <div class="col-span-3 align-middle"><input type="text" disabled class="form-control" :value="k"></div>
                                            <input type="text" class="form-control col-span-8" :id="key" :value="p" :name="k" />
                                            <div class="col-span-1"><XIcon class="mt-2 w-4 h-4 cursor-pointer" @click="showModal('delete-array-item-'+key+'-'+k+'-'+tab.id)"  /></div>
                                        </div>
                                        <!-- Delete Array item -->  
                                        <div :id="'delete-array-item-'+key+'-'+k+'-'+tab.id" class="modal" tabindex="-1" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-body p-0">
                                                        <div class="p-5 text-center">
                                                            <XCircleIcon class="w-16 h-16 text-theme-24 mx-auto mt-3" />
                                                            <div class="text-2xl mt-5">{{ $t('Delete Record') }}</div>
                                                            <div class="text-gray-600 mt-2">{{ "("+k+") "+p }}</div>
                                                            <div class="text-gray-600 mt-2">{{ $t('Are you sure you want to delete the record?') }} <br />{{ $t('This action is irreversible') }}.</div>
                                                        </div>
                                                        <div class="px-5 pb-8 text-center">
                                                            <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                                                            <button type="button" class="btn btn-danger w-24" @click="deleteArrayID(key,k,tab.id)">{{ $t('Delete') }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                      </div>
                                      <div class="col-span-12">
                                          <!-- Add Array item -->  
                                          <div class="input-form mb-3">
                                            <div class="grid grid-cols-12 gap-2">
                                                <div class="col-span-3 align-middle"><input type="text" class="form-control" placeholder="ID" @input="setNewArrayID($event)"></div>
                                                <input type="text" :id="NewArrayID ? key : ''" :name="NewArrayID!='' ? NewArrayID : ''" class="form-control col-span-8" :required="NewArrayID ? true : false" placeholder="Valor"  />
                                            </div>
                                        </div>  
                                      </div>  
                                  </div>  
                              </div>  
                            </div>
                          </div>
                        </div>
                        <div v-else-if="typeof param === 'boolean'"> <!-- Boolean --> 
                          <div class="form-inline mb-5">  
                              <label :for="key" class="form-label w-32 font-medium">{{ key }}</label>
                              <input :id="key" class="form-check-switch" type="checkbox" :checked="param" />
                               <XIcon class="mt-0 ml-2 w-4 h-4 cursor-pointer" @click="showModal('delete-view-attribute-'+key+'-'+tab.id)"  />
                          </div>   
                        </div>
                        <div v-else>  
                           <div class="form-inline mb-5" v-if="key=='icon'"> <!-- Icon -->  
                              <label class="form-label w-40 font-medium text-left">{{ key }}</label>
                              <select :id="key" class="form-select cursor-pointer">
                                  <option :value="icon" v-for="(icon, key) in Icons" :key="key" :selected="icon==param">{{ icon }}</option>
                              </select>
                           </div>
                            <div class="form-inline mb-5" v-else> <!-- Input -->  
                                <label :for="key" class="form-label w-32 font-medium">{{ key }}</label>
                                <input type="text" class="form-control" :id="key" :value="param" />
                                <XIcon class="mt-0 ml-2 w-4 h-4 cursor-pointer" @click="showModal('delete-view-attribute-'+key+'-'+tab.id)" v-if="key!='title'"  />
                            </div>  
                        </div>
                        <!-- Delete Attribute -->  
                        <div :id="'delete-view-attribute-'+key+'-'+tab.id" class="modal" tabindex="-1" aria-hidden="true">
                          <div class="modal-dialog">
                              <div class="modal-content">
                                  <div class="modal-body p-0">
                                      <div class="p-5 text-center">
                                          <XCircleIcon class="w-16 h-16 text-theme-24 mx-auto mt-3" />
                                          <div class="text-xl mt-5">{{ $t('Delete settings parameter') }}</div>
                                          <div class="text-red-600 mt-2">{{ tab.id+" > "+key }}</div>
                                          <div class="text-gray-600 mt-2">{{ $t('Are you sure you want to delete?') }} <br />{{ $t('This action is irreversible') }}.</div>
                                      </div>
                                      <div class="px-5 pb-8 text-center">
                                          <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                                          <button type="button" class="btn btn-danger w-24" @click="deleteAttributeID(key,tab.id)">{{ $t('Delete') }}</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div> 
                    </div>
                    <div class="input-form pt-5 border-t border-gray-200 dark:border-dark-5 mt-5"><button type="submit" class="btn btn-primary">{{ $t('Save') }}</button></div>
                  </form>
              </div>
            </div>
            <!-- Create view attribute (modal) --> 
            <div :id="'modalAttr'+tab.id" class="modal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-10">
                            {{ tab.name }}<br>
                            <div class="text-center mb-3">{{ $t('New configuration parameter') }}</div>
                            {{ $t('Attribute type') }}:<br>
                            <form id="formAttr" class="validate-form" @submit.prevent="saveAttr('formAttr',$event)" ref="formAttr">
                              <input type="hidden" name="tabID" :value="tab.id">
                              <select name="AttType" class="form-select mt-2 sm:mr-2 cursor-pointer" @change="onChangeAtt($event)">
                                  <option value="string">string</option>
                                  <option value="boolean">boolean</option>
                                  <option value="icon">Icon</option>
                              </select>
                              <div class="mt-3" v-if="AttType!='icon'"><input name="AttName" type="text" required class="form-control" :placeholder="$t('Name')" /></div>
                              <div class="mt-3" v-if="AttType=='string'"><input name="AttValue" required type="text" class="form-control" :placeholder="$t('Default value')" /></div>
                              <div class="mt-3" v-if="AttType=='boolean'">
                                  <select name="AttValue" class="form-select cursor-pointer"><option value="true">{{ $t('Yes') }}</option><option value="false">{{ $t('No') }}</option></select>
                              </div>
                              <div class="mt-3" v-if="AttType=='icon'">
                                <input name="AttName" type="text" required class="form-control" :placeholder="$t('Name')" value="icon" />
                                <select name="AttValue" class="form-select cursor-pointer mt-2">
                                    <option :value="icon" v-for="(icon, key) in Icons" :key="key">{{ icon }}</option>
                                </select>
                              </div>
                              <button type="submit" class="btn btn-primary mt-5">{{ $t('Add Attribute') }}</button>
                              <button :id="'close-modalAttr'+tab.id" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Delete view (modal) --> 
            <div :id="'modaldelView'+tab.id" class="modal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0">
                            <div class="p-5 text-center">
                                <XCircleIcon class="w-16 h-16 text-theme-24 mx-auto mt-3" />
                                <div class="text-xl mt-5">{{ $t('Delete settings block') }}</div>
                                <div class="text-red-600 mt-2 text-2xl">{{ tab.name }}</div>
                                <div class="text-red-600 mt-2 font-medium">{{ $t('You will lose all the parameters it contains') }}</div>
                                <div class="text-gray-600 mt-2">{{ $t('No') }} <br />{{ $t('This action is irreversible') }}.</div>
                            </div>
                            <div class="px-5 pb-8 text-center">
                                <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                                <button type="button" class="btn btn-danger w-24" @click="deleteView(tab.id)">{{ $t('Delete') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Notifications -->
      <div id="success-update" class="toastify-content hidden flex"><CheckCircleIcon class="text-theme-10" /> <div class="ml-4 mr-4"><div class="font-medium">{{ $t('Updated configuration') }}</div></div></div>
      <div id="success-saveAttr" class="toastify-content hidden flex"><CheckCircleIcon class="text-theme-10" /> <div class="ml-4 mr-4"><div class="font-medium">{{ $t('A new configuration parameter has been created') }}</div></div></div>
      <div id="success-deleteAttr" class="toastify-content hidden flex"><CheckCircleIcon class="text-theme-10" /> <div class="ml-4 mr-4"><div class="font-medium">{{ $t('Configuration attribute removed') }}</div></div></div>
      <div id="success-addView" class="toastify-content hidden flex"><CheckCircleIcon class="text-theme-10" /> <div class="ml-4 mr-4"><div class="font-medium">{{ $t('A new configuration block has been created') }}</div></div></div>
      <div id="success-deleteView" class="toastify-content hidden flex"><CheckCircleIcon class="text-theme-10" /> <div class="ml-4 mr-4"><div class="font-medium">{{ $t('The configuration block has been removed') }}</div></div></div>
     </div>  
  </div>  
</template>   

<script>

import { defineComponent, onMounted, ref, computed, watch } from "vue"; //useContext, 
//import { _firebase as $_firebase } from "@/model/firebase";
import { useStore } from "@/store";
import cash from "cash-dom";
import { helper as $h } from "@/utils/helper";
import { user as $user } from "@/model/user";
import { event as $event } from "@/model/events";
import { view as $view } from "@/model/view";
import * as rosepetalModel from "rosepetal-model";

export default defineComponent({
  props: {
    tab: {
      type: String,
      default: 'tags',
    }
  },
  created () {
    this.helper = $h
  },
  setup(context, props) {

    var $_firebase    = rosepetalModel._firebase
    const store       = useStore();
    const User        = computed(() => store.state.main.User);
    const root        = ref(false);

    const Tabs        = ref([])
    const CurTab      = ref()
    const Icons       = ref()
    const createView  = ref(false);
    const AttType     = ref('string');  
    const NewArrayID  = ref(false)
    const selOptions  = ref([]); 

    const TabActive       = (t) => { if(t==CurTab.value)return "dark:text-white text-theme-12 font-medium" };
    const ChangeTab       = async (t) => { CurTab.value = t; await initView() };
    const newView         = () => { createView.value = true;};
    const initView        = async ()  => { Tabs.value = await $view.get();  }
    const showModal       = (m) => { AttType.value = "string"; cash("#"+m).modal("show");  };
    const onChangeAtt     = (event)   => { AttType.value = event.target.value }; 
    const setNewArrayID   = (event)   => { NewArrayID.value = event.target.value }; 

    const deleteArrayID   = async (key,name,tab) => { 
      cash('#delete-array-item-'+key+'-'+name+'-'+tab).modal("hide"); cash('#delete-array-item-'+key+'-'+name+'-'+tab).removeClass('show'); 
      let data = {}; data[key] = {}; data[key][name] = $_firebase.firebase().firestore.FieldValue.delete()
      await $view.deleteAttr(tab,data,"#success-deleteAttr").then( async () => { 
         await $event.saveEvent('app.view.delete.attribute', { uid: useStore().state.main.User.uid, view: tab, deleted: key, attribute: name } , false ).then(() => { 
          ChangeTab(tab);  
         });
      });
    }; 

    const deleteAttributeID   = async (key,tab) => { 
       cash('#delete-view-attribute-'+key+'-'+tab).modal("hide"); cash('#delete-view-attribute-'+key+'-'+tab).removeClass('show');
       let data = {}; data[key] = $_firebase.firebase().firestore.FieldValue.delete()
       await $view.deleteAttr(tab,data,"#success-deleteAttr").then( async () => { 
         await $event.saveEvent('app.view.delete.attribute', { uid: useStore().state.main.User.uid, view: tab, deleted: key} , false ).then(() => { 
          ChangeTab(tab);  
          });
       });
    }; 

    const setOptions = async (k) => { 
      if(k=="roles"){
        let users = await $user.list();
        for (const [k,u] of Object.entries(users)){ if(k>=0 && u.email)selOptions.value.push({ id: u.email , title: u.email }) } 
       }
    }; 

    onMounted( async () => { 
      //useContext().expose({ CurTab, Tabs, ChangeTab, initView })
      if(context.tab)CurTab.value = context.tab;
      Icons.value = $h.getIcons();
      await initView()
      if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true; 
    });

   watch( () => store.state.main.User.role, () => { if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true;  });

    return {
      props,
      context,
      Tabs,
      CurTab,
      TabActive,
      ChangeTab,
      initView,
      AttType,
      onChangeAtt,
      NewArrayID,
      selOptions,
      setOptions,
      showModal,
      setNewArrayID,
      deleteArrayID,
      deleteAttributeID,
      Icons,
      newView,
      createView,
      User,
      root
    };
  },
  methods:{
      /*
      updateView: async function(tabID,event){
        if (event) event.preventDefault()
        if(this.$refs['form-'+tabID].length){
          console.log('form ref', this.$refs['form-'+tabID].id)
            const data = {};
            for (let i = 0; i < this.$refs['form-'+tabID].length; i++) {
              console.log(this.$refs['form-'+tabID][i].type)
              if(this.$refs['form-'+tabID][i].type!='submit'){
                 if(this.$refs['form-'+tabID][i].id){
                   
                  if(this.$refs['form-'+tabID][i].type==='select-multiple'){
                      let res = []; let datOpt = {}; let keys = []
                      for (const [key, p] of Object.entries(this.$refs['form-'+tabID][i])) { keys.push(key); if(p.selected)res[res.length] = p.value; } 
                      datOpt[this.$refs['form-'+tabID][i].name] = res;
                      data[this.$refs['form-'+tabID][i].id] = {};
                      data[this.$refs['form-'+tabID][i].id][this.$refs['form-'+tabID][i].name] = res
                  }else{
                      if(this.$refs['form-'+tabID][i].name){
                        let datArr = {}
                        if(data[this.$refs['form-'+tabID][i].id])datArr = data[this.$refs['form-'+tabID][i].id];
                        datArr[this.$refs['form-'+tabID][i].name] = this.$refs['form-'+tabID][i].value
                        data[this.$refs['form-'+tabID][i].id] = datArr;
                      }else{
                        if(this.$refs['form-'+tabID][i].type==='checkbox'){ 
                          let chkBool = Boolean(); if(this.$refs['form-'+tabID][i].checked)chkBool = Boolean(true)
                          data[this.$refs['form-'+tabID][i].id] = chkBool
                        }else{
                          data[this.$refs['form-'+tabID][i].id] = this.$refs['form-'+tabID][i].value
                        }
                      }
                  } 
                 }
               }
            }  
            console.log(data)
            //await $view.update(this.$refs['form-'+tabID].id,data,"#success-update").then(async () =>  {
            //  await $event.saveEvent('app.view.update', { uid: useStore().state.main.User.uid, updated: data} , false ).then(() => { 
            //    this.ChangeTab(tabID)
            //  }) 
            // })
        }
      },*/
      updateView: async function(tabID, event){
        if (event) event.preventDefault()
        const form = event.target; 
        const data = {};
        for (let i = 0; i < form.length; i++) {
          if(form[i].type !== 'submit' && form[i].id){
            if(form[i].type === 'select-multiple'){
              let res = [];
              let datOpt = {};
              let keys = [];
              for (const [key, p] of Object.entries(form[i])) {
                keys.push(key);
                if(p.selected) res.push(p.value);
              }
              datOpt[form[i].name] = res;
              data[form[i].id] = {};
              data[form[i].id][form[i].name] = res;
            } else {
              if(form[i].name){
                let datArr = {};
                if(data[form[i].id]) datArr = data[form[i].id];
                datArr[form[i].name] = form[i].value;
                data[form[i].id] = datArr;
              } else {
                if(form[i].type === 'checkbox'){
                  data[form[i].id] = form[i].checked;
                } else {
                  data[form[i].id] = form[i].value;
                }
              }
            }
          }
        }
        console.log({ formId: form.id, uid: useStore().state.main.User.uid, updated: data} );
        await $view.update(form.id,data,"#success-update").then(async () =>  {
          await $event.saveEvent('app.view.update', { uid: useStore().state.main.User.uid, updated: data}, false).then(() => { 
             this.ChangeTab(form.id)
             }) 
          })
      },
      saveAttr: async function(formID,event){
        if (event) event.preventDefault()
         if(this.$refs[formID].length){
            const data = {};
            for (let i = 0; i < this.$refs[formID].length; i++) {
              if(this.$refs[formID][i].type!='submit'){
                if(this.$refs[formID][i].name=="AttType")data["AttType"]   = this.$refs[formID][i].value;
                if(this.$refs[formID][i].name=="tabID")data["Collection"]  = this.$refs[formID][i].value;
                if(this.$refs[formID][i].name=="AttName")data["AttName"]   = this.$refs[formID][i].value.replace(/ /g, "").toLowerCase();
                if(this.$refs[formID][i].name=="AttValue")data["AttValue"] = this.$refs[formID][i].value;
              }
            }  
            if(data["Collection"] && data["AttType"] && data["AttName"] && data["AttValue"]){
              var newAttr  = {}; var newValue = data["AttValue"];
              if(data["AttType"]=='boolean'){ newValue = Boolean(false); if(data["AttValue"]=="true")newValue = Boolean(true);}
              newAttr[data["AttName"]] = newValue
              await $view.updateAttr(data["Collection"],newAttr).then(async () =>  {
                await $event.saveEvent('app.view.add.attribute', { uid: useStore().state.main.User.uid, created: newAttr} , false ).then(() => { 
                  $h.Notification("#success-saveAttr")
                  cash("#modalAttr"+data["Collection"]).addClass("hidden");
                  document.getElementById("close-modalAttr"+data["Collection"]).click()
                }) 
                this.ChangeTab(data["Collection"]) 
              })
            }
         }
    },
    addView: async function(formID,event){
      if (event) event.preventDefault()
      if(this.$refs[formID].length){
        const view = {};
        for (let i = 0; i < this.$refs[formID].length; i++) {  if(this.$refs[formID][i].name=="view")view.value = this.$refs[formID][i].value.replace(/ /g, "").toLowerCase(); }  
        if(view.value){
           await $view.add(view,"#success-addView").then(async () =>  {
            await $event.saveEvent('app.view.add', { uid: useStore().state.main.User.uid, created: view.value} , false ).then(() => { 
              this.ChangeTab(view.value)
              this.createView = false;
            }) 
          })
        }
      }
    },
    deleteView: async function(viewID,event){
      if (event)event.preventDefault()
      await $view.delete(viewID,"#success-deleteView").then(async () =>  {
        await $event.saveEvent('app.view.delete', { uid: useStore().state.main.User.uid, deleted: viewID} , false ).then(() => { 
          cash('#modaldelView'+viewID).modal("hide"); 
          cash('#modaldelView'+viewID).removeClass('show')
          this.ChangeTab(this.Tabs[0].id)
        }) 
      })
     
    },
    
  }
});
</script>