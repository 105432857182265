<template>
  <div id="backIframeNode" class="w-full h-full">
    <div v-if="layout.error" class="flex flex-col items-center text-center pt-32">
      <img :src="require('@/assets/images/rosepetal/icon/errorNet.png')" class="w-32" />
      <div class="mt-5 text-gray-600">{{ $t('Controller is offline') }}</div>
      <button class="btn flex items-center bg-gray-100 border-gray-300 py-1 px-8 mt-3 text-sm font-normal hover:bg-blue-900 hover:text-white" @click="forceReload()">{{ $t('Retry') }}</button>
    </div>
    <div v-else class="flex flex-col items-center text-center"><img :src="require('@/assets/images/rosepetal/icon/train7.gif')" class="w-72" /></div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, computed,  onUnmounted, watch } from "vue"; //watch
import * as rosepetalModel from "rosepetal-model";
import cash from "cash-dom";
import { useStore } from "@/store";
import config from '../../etc/rosepetal.json';
//import { user as $user } from "@/model/user";

const nodeConfig = config.nodeConfig;

export default defineComponent({
  setup() {

    const store            = useStore();  
    const User             = computed(() => store.state.main.User);
    const layout           = ref({ error: true });
    const userData         = computed(() => store.state.main.userData);
    //const zerotierIP       = ref();

    const loadNR = async (url) => {  

      console.log('in loadNR', nodeConfig)
      layout.value.error  = false;
      const uiFrame       = document.getElementById("uiFrame");
      uiFrame.src         = "about:blank";  
      uiFrame.onload      = async () => {
        let _status       = await rosepetalModel.controller.getStatus()
        if(_status.error){
          cash(".content").removeClass("hidden");
          uiFrame.style.display = 'none';
          layout.value.error    = true;
          uiFrame.src           = "about:blank";  
        }else{
          cash(".content,#backIframeNode").addClass("hidden");
          uiFrame.style.display = 'block';
        }  
      };

      //console.log('USER DATA2', store.state.main)
      console.log('USER', User.value)
      console.log('USER DATA', userData.value)

      if(userData.value && userData.value?.noderedMode== 'direct' && userData.value?.noderedModeHost)uiFrame.src = "https://"+userData.value?.noderedModeHost+ "/nodered" + url + "?uid=" + User.value.uid; 
      else uiFrame.src = nodeConfig.host + "/nodered" + url + "?uid=" + User.value.uid ; 

      console.log("in NR", uiFrame.src)

      if(User)console.log('User444', User.value)
    }

    onMounted( async () => { 
      //zerotierIP.value    = await rosepetalModel.project.getSettings("zerotier")
      /*
      if(User.value && User.value.uid)await $user.getUserData(User.value.uid).then((userData) => { 
        if(userData){ 
          store.dispatch('main/setUserData', userData); 
        }
      })
      */

      cash(".content").addClass("rpContentNodeBackground");
      cash("#topBarUiIframe").css("display", "block");
      cash(".dashboxItem,.side-nav").addClass("hidden");
      cash(".top-bar-node-menu").removeClass("hidden");
      cash(".top-bar-node-menu-side-menu-node").addClass("top-bar-node-menu-current");
      cash("body").css("overflow-y", "hidden");
      /*
      if(cash("#uiFrame").attr("src")=="about:blank") await loadNR('/ui/'); ///ui/#!/0
      else{
        layout.value.error = false;
        cash(".content").addClass("hidden");
        document.getElementById("uiFrame").style.display = 'block';
      }*/
    });

    onUnmounted( () => { 
      cash(".content").removeClass("rpContentNodeBackground");
      cash(".dashboxItem,.side-nav,.content").removeClass("hidden");
      cash(".top-bar-node-menu").addClass("hidden");
      cash("#topBarUiIframe").css("display", "none");
      cash(".top-bar-node-menu-current").removeClass("top-bar-node-menu-current");
      cash("body").css("overflow-y", "scroll");
    });

    const forceReload = async () => { await loadNR('/ui'); } ///ui/#!/0

    watch(User, (newValue) => {
        if(newValue && newValue.uid) {
            loadNR('/ui/');
        }
    });

    return{
      layout,
      forceReload,
      userData
    }

  }
});
</script>
<style>
.rpContentNodeBackground{ background-color: #fff !important; padding: 0 !important; }
</style>