'use strict';

const $_firebase = require("./firebase");

const JSZip     = require("jszip");
const saveAs    = require("file-saver");
const op        = require("@tensorflow/tfjs-core");

const helper    = require("./helper");
const other     = require("./other");
const model     = require("./model");
const event     = require("./event");
const image     = require("./image");
const aws       = require("./aws");
const project   = require("./project");


const dataset = { 
    list: async function(opt = false) {
        let datasets = [];
        let dsets    = $_firebase.firestore().collection('dataset')
        if(opt.type)dsets = dsets.where('type', '==', opt.type)
        if(opt.trained)dsets = dsets.where('trained', '==', Boolean(true))
        if(opt.vertex){ dsets  = dsets.where('automl', '!=', ""); dsets = dsets.orderBy('createdAt', 'desc') }
        if(opt.search)dsets  = dsets.orderBy("name", "asc")
        if(opt.search)dsets  = dsets.where('name', '>=', opt.search.toString().toUpperCase()).where('name', '<=', opt.search.toString().toUpperCase() + '\uf8ff');
        if(opt.limit && !opt.search)dsets   = dsets.limit(opt.limit)
        //else dsets = dsets.orderBy('createdAt', 'desc')
        if(opt.order && opt.order=='dateAsc')dsets = dsets.orderBy('createdAt', 'asc')
        if(opt.order && opt.order=='dateDesc')dsets = dsets.orderBy('createdAt', 'desc')
        var notInVertex = []
        await dsets.get().then(async snapshot => {
            snapshot.forEach(async doc => {
                let item     = doc.data()
                item.id      = doc.id;
                let pushItem = true
                item.createdDate        = item.createdAt ? helper.getTimestampDate(item.createdAt.toDate(),'full') : false 
                if(item.updatedAt)item.updatedDate = helper.getTimestampDate(item.updatedAt.toDate(),'full')
                if(item.name)item.name  = item.name.toUpperCase();
                if(item.name)item.name  = helper.capitalize(item.name)
                if (item.deleted)pushItem = false
                if (item.type){
                    switch (item.type) {
                        case "MULTICLASS": item.typeName = "Classification"; break;
                        case "MULTILABEL": item.typeName = "Segmentation"; break;
                        case "imageObjectDetection": item.typeName = "Object detection"; break;
                        default: item.typeName = false; break;
                    }
                }
                if(!item.automl)notInVertex.push(item.id)
                if(pushItem)datasets.push(item)
            });
        });
        if(datasets.length){
            for (let index in datasets) {
                if(opt.preview){
                    let getPreview = await this.getPreview(datasets[index].id)
                    if(getPreview.preview)datasets[index].preview = getPreview.preview
                }
                /*if(notInVertex.length){
                    let indexNotInVertex = notInVertex.indexOf(datasets[index].id)
                    if(indexNotInVertex>-1){
                        let createVertexDataset = await this.createVertex(datasets[index].id)
                        if(createVertexDataset.automl)datasets[index].automl = createVertexDataset.automl
                    }
                }*/
            }
        }  
        return datasets
    },

    get: async function(datasetID, opt = false) {
        let dataset = {};
        let dsets = $_firebase.firestore().collection('dataset').doc(datasetID)
        await dsets.get().then(async snapshot => {
            dataset = snapshot.data()
            if(dataset){
                dataset.id = snapshot.id;
                if(dataset.name)dataset.name  = helper.capitalize(dataset.name)
                if (dataset.type){
                    switch (dataset.type) {
                        case "MULTICLASS": dataset.typeName = "Classification"; break;
                        case "MULTILABEL": dataset.typeName = "Segmentation"; break;
                        case "imageObjectDetection": dataset.typeName = "Object detection"; break;
                        default: dataset.typeName = false; break;
                    }
                }
                if(dataset.createdAt)dataset.createdDate = helper.getTimestampDate(dataset.createdAt.toDate(),'full')
            }
        });
        if(opt && dataset && Object.keys(dataset).length){ 
            if(opt.models){ let _models = await this.getModels(dataset.id); dataset.models = _models.models }
            if(opt.tagsCounter)dataset.tagsCounter   = await this.getTagsCounter(dataset.id)
            if(opt.dataDivision)dataset.dataDivision = await this.getDataDivision(dataset.id) 
            if(opt.preview)dataset.preview           = await this.getImages({ datasetID: dataset.id, preview: true });
            if(opt.ref)dataset.datsetRef = dsets
        }  
        return dataset
    },

    getPreview: async function(datasetID) {
        let resp = { status: "error", error: false, preview: false }
        if (datasetID){
            let datesetRef   = $_firebase.firestore().collection("dataset").doc(datasetID.toString())
            let images       = $_firebase.firestore().collection('image').where('dataset', '==', datesetRef).orderBy('date', 'desc').limit(1)
            let snap         = await images.get()
            if(Object.keys(snap).length){
                snap.forEach(async (doc) => {
                    let p = doc.data()
                    p.id = doc.id
                    if(p.imageData && p.imageData._delegate._byteString.binaryString)
                        resp.preview = "data:image/png;base64," + btoa(p.imageData._delegate._byteString.binaryString)
                });
            }else{ resp.error = "The dataset does not contain images" } 
        }else{ resp.error = "dataset Id is required" } 
        return resp
    },

    getVertex: async function(datasetID) {
        let resp = { status: "error", error: false }
        if (datasetID){
            let dataset  = await this.get(datasetID)
            //let config   = project.getConfig()
            resp.dataset = datasetID 
            if (dataset.automl){
                resp.automl  = dataset.automl 
                let dsVertex = await other.httpsCallable('api/model/datasets/dataset_id/' + dataset.automl )
                //console.log('call usapi method')
                //const functionsUsApi = $_firebase.firebase.app().functions(config.functions.usapi)
                //const dsVertex = await functionsUsApi.httpsCallable('model/datasets/dataset_id/' + dataset.automl );
                if(dsVertex.data){
                    resp.response = dsVertex.data
                    resp.status   = "success"
                }else{ resp.error = "dataset " + dataset.automl + " not found in Vertex AI" } 
            }else{ resp.error = "dataset is not created in Vertex AI" } 
        }else{ resp.error = "dataset Id is required" } 
        return resp
    },

    generateResume: async function(datasetID, opt = {}) {
        let resp = { status: "error", error: false, resume: "" }
        if (datasetID){
            let dataset  = await this.get(datasetID)
            if(opt.tagsCounter)dataset.tagsCounter = opt.tagsCounter
            else dataset.tagsCounter = await this.getTagsCounter(datasetID)
            if(dataset.type=='imageObjectDetection')resp.resume = 'This data set is based on the classification of image by object detection.'
            if(dataset.type=='MULTILABEL')resp.resume = 'This dataset is based on the Image classification with multiple labels.'
            if(dataset.type=='MULTICLASS')resp.resume = 'This dataset is based on the Image classification with a single label.'
            if(dataset.tagsCounter.tags && Object.keys(dataset.tagsCounter.tags).length>1){
                resp.resume += ' Contains '+(Object.keys(dataset.tagsCounter.tags).length-1)+' classification tags and '+dataset.tagsCounter.count+' images, of which '+dataset.tagsCounter.labeled+' are labeled.'
            }
            if(dataset.tagsCounter.tagslabeled && Object.keys(dataset.tagsCounter.tagslabeled).length){
                resp.resume += ' Contains '+(Object.keys(dataset.tagsCounter.tagslabeled).length)+' classification tags and '+dataset.tagsCounter.count+' images, of which '+dataset.tagsCounter.labeled+' are labeled.'
                resp.resume += ' and '+dataset.tagsCounter.nolabel+' images are unclassified. Remember that unclassified images will not be used for training.'
            }
            if(dataset.trained && opt.models && opt.models.count)resp.resume +=' The set is trained and ' + opt.models.count + 'prediction models have been generated.'
            resp.status   = "success"
        }else{ resp.error = "dataset Id is required" } 
        return resp
    },

    getVertexItems: async function(datasetID) {
        let resp = { status: "error", error: false }
        if (datasetID){
            let dsVertexItems = await other.httpsCallable('api/model/dataitems/dataset_id/' + datasetID.toString() )
            if(dsVertexItems.data){
                resp.response = dsVertexItems.data
                resp.status   = "success"
            }else{ resp.error = "dataset " + datasetID + " not found Items in Vertex AI" } 
        }else{ resp.error = "dataset Id is required" } 
        return resp
    },

    getVertexItem: async function(dataiItem, dataItemsAnnotations = []) {
        let resp = { status: "error", error: false, data: [] }
        if (dataiItem){
            let dsVertexItem = await other.httpsCallable('api/model/annotations/dataitem/' + dataiItem.replace(/\//g, "_") )
            if(dsVertexItem.data){
                resp.data = dsVertexItem.data
                if (dataItemsAnnotations){
                    if (resp.data.length) {
                        for (let a = 0; a < resp.data.length; a++) {
                            if (resp.data[a].displayName.stringValue) {
                                let label  = resp.data[a].displayName.stringValue
                                let exists = false
                                dataItemsAnnotations.findIndex(function (lbl, index) {  if (lbl.name == label) { exists = true; dataItemsAnnotations[index].count++ }  });
                                if (!exists) dataItemsAnnotations.push({ name: label, count: 1 })
                            }
                        }
                    }    
                    resp.data =  dataItemsAnnotations 
                }
                resp.status   = "success"
            }else{ resp.error = "dataset item " + dataiItem + " not found Items in Vertex AI" } 
        }else{ resp.error = "dataiItem Id is required" } 
        return resp
    },

    getVertexCsv: async function(datasetID, set = false) {
        let resp = { status: "error", error: false, csv: [] }
        if (datasetID){
            let call = 'api/dataset/' + datasetID + '/csv'
            if (set.method && set.method == "auto") call = call + '?test=' + set.test + "&validation=" + set.validation; 
            let csv = await other.httpsCallable(call)
            if(csv.data){
                resp.csv = csv
                if (csv.data && csv.data.training && csv.data.training.dataset)await this.update(datasetID,{ importCsv: csv.data.training.dataset }) 
                resp.status   = "success"
            }else{ resp.error = "dataset not found CSV with division data" } 
        }else{ resp.error = "datasetI Id is required" } 
        return resp
    },

    getAws: async function(datasetID) {
        let resp = { status: "error", error: false }
        if (datasetID){
            let dataset  = await this.get(datasetID)
            resp.dataset = datasetID 
            if (dataset.aws && dataset.aws.length){
                resp.awsProjects  = {} 
                for(let i=0 ; i < dataset.aws.length ; i++){
                    let projectAws = await aws.getProject(dataset.aws[i])
                    if(!projectAws.error){
                        let projectAwsDatasets = await aws.getDataset(dataset.aws[i])
                        projectAws.response.ProjectDescription.Datasets = projectAwsDatasets.response
                        resp.awsProjects[dataset.aws[i]] = projectAws.response
                    }
                }  
            }else{ resp.error = "dataset project is not created in Lookout vision AI" } 
        }else{ resp.error = "dataset Id is required" } 
        return resp
    },

    update: async function(datasetID, data) {
        let resp = ''
        if (datasetID){
            data["updatedAt"] = $_firebase.firebase.firestore.FieldValue.serverTimestamp()
            await $_firebase.firestore().collection("dataset").doc(datasetID).set(data, { merge: true } ) //.update(data)
                .then( async function () { 
                    await event.saveEvent('dataset.update',{ dataset: datasetID, data: data }, false); //uid: useStore().state.main.User.uid, 
                    resp = '{ result: "success", error: false }'
                })
                .catch(async () => { resp = '{ result: "error", error: "Dataset not found" }'});
        }
        return resp
    },

    delete: async function(datasetID) {
        await event.saveEvent('dataset.delete',{ dataset: datasetID }, false); //uid: useStore().state.main.User.uid, 
        return await this.update(datasetID,{ deleted : Boolean(true) })
    },

    createCopyOperation: async function(datasetID, operation, usapi) {
        let resp               = { status: "error", error: false }
        resp.status            = "success" 
        const options          = {method: "POST", body: JSON.stringify({images: operation.images}) };
        //await other.httpsCallable('api/dataset/' + datasetID + '/copyOperation/' + encodeURIComponent(operation.images));
        //await other.httpsCallable('api/dataset/' + datasetID + '/copyOperation/', options);
        const functionsUsApi  = $_firebase.firebase.app().functions(usapi)
        const action          = functionsUsApi.httpsCallable('dataset/' + datasetID + '/copyOperation');
        //await action(data, { body: JSON.stringify(data) }).then(response => {resp = response }).catch((error) => { resp.error = error });
        await action(options, { body: JSON.stringify(options) }).then(() => {  }).catch(async (error) => { resp.status = "error"; resp.error = error });
        return resp
    },


    createMoveOperation: async function(datasetID, operation, usapi) {
        let resp               = { status: "error", error: false }
        resp.status            = "success"
        const options          = {method: "POST", body: JSON.stringify({images: operation.images}) };
        const functionsUsApi  = $_firebase.firebase.app().functions(usapi)
        const action          = functionsUsApi.httpsCallable('dataset/' + datasetID + '/moveOperation');
        await action(options, { body: JSON.stringify(options) }).then(() => {  }).catch(async (error) => { resp.status = "error"; resp.error = error });
        return resp
    },

    deleteImagesBatch: async function(datasetID, images) {
        let resp               = { status: "error", error: false }
        resp.status            = "success" 
        await other.httpsCallable('api/dataset/' + datasetID + '/deleteBulk/' + encodeURIComponent(images));
        return resp
    },

    getModels: async function(datasetID) {
        const modelObj = require("./model");
        let resp = { status: "error", error: false, count: 0, models: [] }
        let dsRef  = $_firebase.firestore().collection('dataset').doc(datasetID)
        let model  = $_firebase.firestore().collection('model').where('dataset', '==', dsRef).orderBy('createdAt', 'desc')
        await model.get().then(async snapshot => {
            snapshot.forEach(async doc => {
                let item = doc.data()
                item.id = doc.id;
                if(item.createdAt)item.created = helper.getTimestampDate(item.createdAt.toDate(),'full')
                if (!item.deleted)resp.models[resp.models.length] = item
                /*if(!item.deleted && item.trainOperation && item.trainOperation.operationName && !item.automl){
                    let trainingPipelineOperation  = await other.httpsCallable('api/model/trainingpipeline/status/' + item.trainOperation.operationName.replace(/\//g, "--")) 
                    if(trainingPipelineOperation.data){
                        if(trainingPipelineOperation.data.state=="PIPELINE_STATE_SUCCEEDED" || trainingPipelineOperation.data.state=="PIPELINE_STATE_FAILED"){
                            modelObj.update(item.id,{ automl : trainingPipelineOperation.data.modelToUpload.name.toString().split('/').pop() })
                        }
                    }
                }*/
            });
        });
        resp.count  = resp.models.length
        if(resp.models.length)resp.status  = "success"
        else resp.error = "no found models linked to this dataset"
        return resp
    },

    updateAutomlId: async function(datasetID) { 
        const modelObj = require("./model");
        let dsRef  = $_firebase.firestore().collection('dataset').doc(datasetID)
        let model  = $_firebase.firestore().collection('model').where('dataset', '==', dsRef).orderBy('createdAt', 'desc')
        await model.get().then(async snapshot => {
            snapshot.forEach(async doc => {
                let item = doc.data()
                item.id = doc.id;
                //update automl id after training
                if(!item.deleted && item.trainOperation && item.trainOperation.operationName && !item.automl){
                    let trainingPipelineOperation  = await other.httpsCallable('api/model/trainingpipeline/status/' + item.trainOperation.operationName.replace(/\//g, "--")) 
                    console.log('ESTA ENTRENANDO EL MODELO'+item.id, trainingPipelineOperation.data)
                    if(trainingPipelineOperation.data){
                        if(trainingPipelineOperation.data.state=="PIPELINE_STATE_SUCCEEDED" || trainingPipelineOperation.data.state=="PIPELINE_STATE_FAILED"){
                            modelObj.update(item.id,{ automl : trainingPipelineOperation.data.modelToUpload.name.toString().split('/').pop() })
                        }
                    }
                }
            });
        });
    },

    /*async getModels(datasetID) {
        let resp = { status: "error", error: false, count: 0, models: [] }
        let dsRef  = $_firebase.firestore().collection('dataset').doc(datasetID)
        let model  = $_firebase.firestore().collection('model').where('dataset', '==', dsRef)
        await model.get().then(async snapshot => {
            await snapshot.forEach(async doc => {
                let item = doc.data()
                item.id = doc.id;
                if(item.createdAt)item.created = $h.getTimestampDate(item.createdAt.toDate(),'full')
                if (!item.deleted)resp.models[resp.models.length] = item
            });
        });
        resp.count  = resp.models.length
        if(resp.models.length)resp.status  = "success"
        else resp.error = "no found models linked to this dataset"
        return resp
    },*/

    create: async function(opt) {
        opt.name = opt.name.replace(/\s/g, "_").replace(/[^\w\s]/gi, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").substring(0, 32).toLowerCase();
        let saveData = { name: opt.name, type: opt.type, createdAt: $_firebase.firebase.firestore.FieldValue.serverTimestamp(), description: "" }
        if(opt.description)saveData.description = opt.description
        let resp = { status: "error", error: false }
        await $_firebase.firestore().collection("dataset").doc(opt.name.toString()).set(saveData)
            .then( async function () {   
                resp.dataset = opt.name.toString() 
                resp.status  = "success"
            })
            .catch(function (error) { resp.error = error });  
        if(resp.status=="success"){
            await event.saveEvent('dataset.create',{  dataset: opt.name.toString(), qry: saveData }, false); //uid: useStore().state.main.User.uid,
            if(opt.type=="MULTILABEL"){
                await this.createTag(opt.name.toString(), { tag: "0",  name: "Anomaly", unclassified: true, anomaly: true, color: "#BC2B5F", description: "Used for Anomaly images" })
                await this.createTag(opt.name.toString(), { tag: "OK", name: "Normal", normal: true, color: "#2BBC33", description: "Used for Normal images"})
            }else{
                await this.createTag(opt.name.toString(), { tag: "0", name: "unclassified", unclassified: true })
            }
        }
        return resp
    },

    createVertex: async function(datasetId) {
        let resp = { status: "error", error: false }
        if (datasetId){
            let dataset  = await this.get(datasetId)
            resp.dataset = datasetId 
            if (!dataset.automl){
                //let valideTrain  = await this.validateToTrain(datasetId)
                //if(valideTrain.validated){
                    let vxName = dataset.name.replace(/-/g, "_")
                    let createVertex = await other.httpsCallable('api/model/create/dataset/' + vxName.toLowerCase() + '-' + dataset.type)
                    if(createVertex.data){
                        await event.saveEvent('dataset.create.vertex',{  dataset: dataset.name.toString(), response: JSON.stringify(createVertex.data) }, false); //uid: useStore().state.main.User.uid,
                        resp.automl  = createVertex.data.name.toString().split('/').pop()
                        await this.update(resp.dataset, { automl : resp.automl })
                        resp.status  = "success" 
                    }else{ resp.error = "failed to create dataset " + (createVertex.error ? createVertex.error : "") } 
                //}else{ resp.error = "dataset is not validated for a training, review the errors."; resp["errors"] = valideTrain.errors; resp["warnings"] = valideTrain.warnings } 
            }else{ resp.error = "The dataset is already created in vertex with the identifier: " + dataset.automl } 
        }else{ resp.error = "dataset Id is required" } 
        return resp
    },

    createAws: async function(datasetId, tagMap=false, uploadImageType = "image/png") {
        let resp = { status: "error", error: false, response: {} }
        if (datasetId){
        let dataset    = await this.get(datasetId)
        resp.dataset   = datasetId 
        if(dataset.type=="MULTICLASS" || dataset.type=="MULTILABEL"){
            let awsConfig    = aws.getConfig()
            var nowdate      = new Date()
            resp.projectName = awsConfig.projectId + "-" + dataset.name.toString().replace(/\s+/g, '_') + "-" + nowdate.getTime() 
            let newProject = await aws.createProject(resp.projectName)   
            if(!newProject.response.ProjectMetadata){ resp.error = "failed to create project" 
            }else{
                console.log('Project '+ resp.projectName + ' created!')
                let dsAws = dataset.aws ? dataset.aws : []
                dsAws.push(resp.projectName)
                await this.update(resp.dataset, { aws : dsAws })
                const r = await this.uploadS3(datasetId, resp.projectName, tagMap, uploadImageType)
                resp.status   = "success"
                console.log('uploadS3 finish and wait 30 seconds to create manifest', resp)
                
                await new Promise(resolve => setTimeout(resolve, 30000));

                console.log('Finish upload s3 > Create Datasets with manifests',r)

                if(r.response.error && !r.error)r.error = r.response.error
                if (!r.error){
                    let newDatasetTest = await aws.createDataset("test", resp.projectName, r.response.manifest.test) 
                    if(newDatasetTest.error){ 
                        resp.error = newDatasetTest.error 
                        resp.from = "createDataset test";
                    }else{
                        resp.response.test = newDatasetTest.response
                        let newDatasetTrain = await aws.createDataset("train", resp.projectName, r.response.manifest.train) 
                        if(newDatasetTrain.error){ 
                            resp.error = newDatasetTrain.error 
                            resp.from = "createDataset train";
                        }else{   
                            resp.response.train = newDatasetTrain.response
                            resp.status   = "success"
                        } 
                    }     
                }else{ resp.from = "then uploadS3 have error"; resp.error = r.error; resp.r = r;  }
            }
        }else{ resp.error = "only for classification type datasets, is " + dataset.type } 
        }else{ resp.error = "dataset Id is required" } 
        return resp
    },

    createTag: async function(datasetId, opt) {
        let resp = { dataset: datasetId };
        if(opt.tag && datasetId){
            let tag = {
                name:         opt.name ? opt.name : opt.tag,
                description:  opt.description ? opt.description : "",
                imageCounter: Number(0),
                color:        opt.color ? opt.color : helper.StringtoHex(opt.tag),
                unclassified: opt.unclassified ? Boolean(true) : Boolean(false),
                createdAt:    $_firebase.firebase.firestore.FieldValue.serverTimestamp(),
                //uid:          useStore().state.main.User.uid
            }
            if(opt.anomaly)tag.anomaly = Boolean(true)
            if(opt.normal)tag.normal   = Boolean(true)
            await $_firebase.firestore().collection("dataset").doc(datasetId.toString()).collection('tag').doc(opt.tag).set(tag)
                   .then(async function () {  resp.status = "success";  await event.saveEvent('dataset.tag.create',{ datasetId: datasetId, tag: tag }, false); }) //uid: useStore().state.main.User.uid, 
                   .catch(function (error) { resp.status = "error"; resp.error = error });
        }else{ resp.status = "error"; resp.error = "new tag id and dataset id is required" }
        return resp
    },

    updateTag: async function(Tag) {
        Tag.data["updatedAt"] = $_firebase.firebase.firestore.FieldValue.serverTimestamp()
        await $_firebase.firestore().collection("dataset").doc(Tag.dataset.toString()).collection('tag').doc(Tag.id).set(Tag.data, { merge: true } )//.update(Tag.data);
        await event.saveEvent('dataset.tag.update',{ dataset: Tag.dataset.toString(), id: Tag.id, data: Tag.data }, false); //uid: useStore().state.main.User.uid, 
    },

    deleteTag: async function(Tag) {
        let resp    = { status: "success", error: false, message: "tag deleted" }
        await $_firebase.firestore().collection("dataset").doc(Tag.dataset.toString()).collection('tag').doc(Tag.id).delete()
        return resp
    },

    checkImagesSize: async function(datasetId) {
        let resp = { status: "error", error: false }
        if(datasetId){
            let media = await this.getImages({ datasetID: datasetId })
            if(media.count){  
                for (var i = 0; i < Object.keys(media.media).length; i++) {
                    if(media.media[i].uri){
                        let imgRef = await $_firebase.storage().refFromURL(media.media[i].uri).getDownloadURL(); 
                        let img = new Image();
                        img.onload = function() {  console.log(`Image ${media.media[i].id} width: ${this.width}, height: ${this.height}`); };
                        img.src = imgRef;
                    }
                }  
                resp.status = "success"
            } else resp.error = "dataset not have images"
        } else resp.error = "dataset Id is required";
        return resp
    },

    countImages: async function(datasetId) {
        let resp = { dataset: datasetId };
        //convert resp to json
        resp = JSON.parse(JSON.stringify(resp))
        if(datasetId){
            let dataset = await this.get(datasetId)
            if(dataset){
                let images = await $_firebase.firestore().collection("dataset").doc(datasetId.toString()).collection('image').get()
                resp.count = images.size
                await this.update(datasetId, { imageCounter: resp.count })
            }else{ resp.error = "dataset not found" }
        }else{ resp.error = "dataset Id is required" }
        return resp
    },

    getTags: async function(datasetID, unclassified = true) {
        let tags        = {}
        if(datasetID){
            let dataset     = $_firebase.firestore().collection('dataset').doc(datasetID.toString()).collection('tag').orderBy("name", "asc")
            const docs      = await dataset.get();
            const promises  = docs.docs.map(async (doc) => {
                let item    = doc.data();
                item.id     = doc.id;
                if (item.id === "0") {
                  item.name = item.name ? item.name : "Unclassified";
                  const labeledDoc = await doc.ref.collection('labeled').doc('counter').get();
                  if (labeledDoc.exists)item.labeled = labeledDoc.data();
                }
                if(item.name)item.name = helper.capitalize(item.name)
                if (!item.color) {
                    item.color = helper.StringtoHex(doc.id);
                    this.updateTag({ id: doc.id, dataset: datasetID.toString(), data: { color: item.color }})
                }
                if (unclassified) { tags[doc.id]  = item; } else { if (!item.unclassified && doc.id!="0") tags[doc.id] = item; }
              });
              await Promise.all(promises);
            /*await dataset.get().then(snapshot => {
                snapshot.forEach(async doc => {
                    let item = doc.data()
                    item.id = doc.id;
                    if(item.id=="0"){
                        item.name    = "Unclassified"
                        await doc.ref.collection('labeled').doc('counter').get().then(doc => { if(doc.exists)item.labeled = doc.data() })
                    }
                    if(item.name)item.name = helper.capitalize(item.name)
                    if (!item.color) {
                        item.color = helper.StringtoHex(doc.id);
                        this.updateTag({ id: doc.id, dataset: datasetID.toString(), data: { color: item.color }})
                    }
                    if (unclassified) { tags[doc.id]  = item; } else { if (!item.unclassified && doc.id!="0") tags[doc.id] = item; }
                    
                });
            });*/
        }
        return tags;
    },

    getTag: async function(datasetID, tagId){
        let tag = {}
        let snapshot = await $_firebase.firestore().collection('dataset').doc(datasetID.toString()).collection('tag').doc(tagId).get(); 
        if(snapshot.data())tag = snapshot.data(); 
        if(tag)tag.id = snapshot.id
        if(tag.createdAt)tag.created = helper.getTimestampDate(tag.createdAt.toDate(),'full')
        return tag;
    },

    /*
    getTagsCounter: async function(datasetID, unclassified = true , opt = false) {
        let dataset         = opt.dataset ? opt.dataset : await this.get(datasetID, { ref: true })
        let tagsCounter     = { count: 0, tags: {}, labeled: 0, nolabel:0, names: {}, colors: {}, progessPercent: 0}
        if(dataset.type){
            let tags            = await this.getTags(datasetID, unclassified)
            var totalObjImages  = 0 
            if(Object.keys(tags).length && opt.tagMap){
                let tagsByTagMap = {}
                for (const k of Object.keys(tags)){
                    let inMap = false
                    Object.keys(opt.tagMap.tags).forEach(key => {
                        if(opt.tagMap.tags[key].tags[k])inMap = true 
                    });
                    if(inMap){
                        tagsByTagMap[k] = tags[k]
                        totalObjImages += tags[k].imageCounter
                    }
                }
                tags = tagsByTagMap
                if(dataset.type=='imageObjectDetection' || dataset.type=='MULTILABEL'){
                    let _obsjDivision  = await this.getDataDivision(datasetID, true, false, opt.tagMap)
                    totalObjImages     = _obsjDivision.total
                }
            }else{ if(tags["0"])totalObjImages = tags["0"].imageCounter  }
            if(Object.keys(tags).length){ 
                if(dataset.type=='MULTICLASS')tagsCounter.nolabel =  tags["0"] && tags["0"].imageCounter ? tags["0"].imageCounter : 0
                if(dataset.type=='imageObjectDetection' || dataset.type=='MULTILABEL'){
                    tagsCounter.tagslabeled       = {}
                    if(opt.labeledImages)tagsCounter.tagslabeledImg    = {}
                    tagsCounter.count = totalObjImages
                }
                for (const k of Object.keys(tags)){
                    let _tagcount =  tags[k].imageCounter ? tags[k].imageCounter : 0
                    if(dataset.type=='MULTICLASS'){
                        tagsCounter.count       +=  _tagcount
                        if(!unclassified && k != '0')tagsCounter.tags[k] =  _tagcount
                        else tagsCounter.tags[k] =  _tagcount
                    }
                    if(k != '0'){
                        if(dataset.type=='imageObjectDetection' || dataset.type=='MULTILABEL'){
                            tagsCounter.tagslabeled[k]    = _tagcount
                            if(opt.labeledImages){
                                let stagRef         = $_firebase.firestore().collection("dataset").doc(datasetID.toString()).collection("tag").doc(tags[k].id)
                                let images          = $_firebase.firestore().collection('image').where('dataset', '==', dataset.datsetRef).where('tagsContained', 'array-contains', stagRef).orderBy("tags").orderBy("date", "desc") 
                                let snap            = await images.get()
                                tagsCounter.tagslabeledImg[k] = snap.size
                            }
                        }
                        tagsCounter.labeled += _tagcount 
                    }
                    tagsCounter.colors[k]  = (tags[k] && tags[k].color ? tags[k].color : helper.StringtoHex(k))
                    tagsCounter.names[k]   = tags[k].name
                }
                if(dataset.type=='imageObjectDetection' || dataset.type=='MULTILABEL'){ // && dataset.datsetRef
                    if(opt.tagMap){
                        tagsCounter.nolabel = 0
                        tagsCounter.labeled = tagsCounter.count
                    }else{
                        let datsetRef       = $_firebase.firestore().collection("dataset").doc(datasetID.toString())
                        let images          = $_firebase.firestore().collection('image').where('dataset', '==', datsetRef).where('tags', '==', []) //dataset.datsetRef
                        let snap            = await images.get()
                        tagsCounter.nolabel = snap.size
                        tagsCounter.labeled = parseInt(tagsCounter.count-tagsCounter.nolabel)
                    } 
                }
            } 
            tagsCounter.chart = { labels: ["Labeled"], datasets: [{ data: [tagsCounter.labeled], backgroundColor: [helper.StringtoHex("LabeledGreen")], hoverBackgroundColor: [helper.StringtoHex("LabeledGreen")], borderWidth: 5, borderColor: "#fff"}]}
            if(tagsCounter.nolabel){
                tagsCounter.chart.labels.push("Unclassified")
                tagsCounter.chart.datasets[0].data.push(tagsCounter.nolabel)
                tagsCounter.chart.datasets[0].backgroundColor.push(helper.StringtoHex("UnclassifiedRed"))
                tagsCounter.chart.datasets[0].hoverBackgroundColor.push(helper.StringtoHex("UnclassifiedRed"))
            }
        }
        return tagsCounter;
    },
    */
  
    refreshCounters: async function(datasetID,usapi) {
        let resp              = { status: "success", error: false }
        const functionsUsApi  = $_firebase.firebase.app().functions(usapi)
        const action          = functionsUsApi.httpsCallable('dataset/' + datasetID + '/refreshCounters');
        await action({}).then(() => {  }).catch(async (error) => { resp.status = "error"; resp.error = error });
        return resp
    },

    getTagsCounter: async function(datasetID, unclassified = true , opt = false) {
        let dataset         = opt.dataset ? opt.dataset : await this.get(datasetID, { ref: true })
        let tagsCounter     = { count: 0, tags: {}, labeled: 0, nolabel: 0, names: {}, colors: {}, progessPercent: 0}
        if(dataset.type){
            let tags        = await this.getTags(datasetID, true)
            if(dataset.type=='imageObjectDetection' || dataset.type=='MULTILABEL'){
                tagsCounter.tagslabeled       = {}
                if(opt.labeledImages)tagsCounter.tagslabeledImg    = {}
            }
            if(Object.keys(tags).length){ 
                for (const k of Object.keys(tags)){
                    let countTag = true
                    if(k=="0" && !unclassified)countTag = false
                    if(opt.tagMap){
                        let inMap  = false
                        countTag = false
                        Object.keys(opt.tagMap.tags).forEach(key => { if(opt.tagMap.tags[key].tags[k])inMap = true  });
                        if(inMap)countTag = true
                    }
                    if(countTag){
                        if(dataset.type=='MULTICLASS'){
                            tagsCounter.count += tags[k].imageCounter 
                            if(k!="0")tagsCounter.labeled += tags[k].imageCounter 
                            tagsCounter.tags[k] = tags[k].imageCounter
                        }
                        if(dataset.type=='imageObjectDetection' || dataset.type=='MULTILABEL'){
                            if(k!="0" || dataset.type=='MULTILABEL'){
                                tagsCounter.tagslabeled[k] = tags[k].annotationCounter ? tags[k].annotationCounter : 0
                                if(opt.labeledImages)tagsCounter.tagslabeledImg[k] = tags[k].imageCounter 
                            }
                         }
                        tagsCounter.colors[k]  = (tags[k] && tags[k].color ? tags[k].color : helper.StringtoHex(k))
                        tagsCounter.names[k]   = tags[k].name
                    }
                }
                if(dataset.type=='imageObjectDetection'){
                    tagsCounter.count   = tags["0"].imageCounter 
                    tagsCounter.labeled = tags["0"].labeled ? tags["0"].labeled.imageCounter : 0
                }  
                if(dataset.type=='MULTILABEL'){
                    console.log('ES MULTILABEL Y TIENE', tags["0"].imageCounter, tags["OK"].imageCounter, (parseInt(tags["0"].imageCounter + tags["OK"].imageCounter)))
                    tagsCounter.count   = parseInt(tags["0"].imageCounter + tags["OK"].imageCounter)
                    tagsCounter.labeled = parseInt(tags["0"].imageCounter + tags["OK"].imageCounter)
                    tagsCounter.multi   = { normal: tags["OK"].imageCounter, anomaly: tags["0"].imageCounter }
                }  
                tagsCounter.nolabel = parseInt(tagsCounter.count-tagsCounter.labeled) 
            }
            tagsCounter.chart = { labels: ["Labeled"], datasets: [{ data: [tagsCounter.labeled], backgroundColor: [helper.StringtoHex("LabeledGreen")], hoverBackgroundColor: [helper.StringtoHex("LabeledGreen")], borderWidth: 5, borderColor: "#fff"}]}
            if(tagsCounter.nolabel){
                tagsCounter.chart.labels.push("Unclassified")
                tagsCounter.chart.datasets[0].data.push(tagsCounter.nolabel)
                tagsCounter.chart.datasets[0].backgroundColor.push(helper.StringtoHex("UnclassifiedRed"))
                tagsCounter.chart.datasets[0].hoverBackgroundColor.push(helper.StringtoHex("UnclassifiedRed"))
            }
        }
        return tagsCounter;
    },

    getImages: async function(opt = false) {
        console.log('in getImages',opt)
        
        let media      = { media: [] }
        let images     = $_firebase.firestore().collection('image')
        let order      = { param: 'date', condition: '!=', val:  '', order:  "date", type: "desc"  }
        let pagination = { currentPage: 0, perPage: opt.perPage ? opt.perPage : 12, pages: 1, total: 0, init:  null, first: null, last:  null, prev:  false, next:  true, toend: false }
        if(opt.order)order = opt.order
        if(opt.paginationQry)pagination = opt.paginationQry
        if(opt.perPage)opt.pagination = true
        if(opt.datasetID) {
            let dataset      = opt.dataset ? opt.dataset : await this.get(opt.datasetID)
            let unclassRef   = $_firebase.firestore().collection("dataset").doc(opt.datasetID.toString()).collection('tag').doc("0")
            media.type       = dataset.type
            if(opt.preview)opt.limit = 1
            //if (dataset.type && dataset.type!='MULTICLASS')opt.pagination = false //pagination only MULTICLASS
            //if (opt.objDivision && opt.objDivision!= 'all' && opt.pagination)opt.pagination = opt.objDivision = false //pagination only whithout set
            let tagRef   = $_firebase.firestore().collection("dataset").doc(opt.datasetID.toString())
            if (dataset.type && (dataset.type=='MULTICLASS' || dataset.type=='MULTILABEL') && opt.objByTag && opt.objByTag!= 'all'){
                if (dataset.type=='MULTICLASS'){
                    tagRef = tagRef.collection('tag').doc(opt.objByTag.toString())
                    images = images.where('tag', '==', tagRef)
                }
                if (dataset.type=='MULTILABEL'){
                   if(opt.objtagsType && opt.objtagsType == 'anomaly')images = images.where('tag', '==', unclassRef)
                   else {
                    tagRef = tagRef.collection('tag').doc(opt.objByTag.toString())
                    images = images.where('tag', '==', tagRef)
                   }
                }
            }else{ 
                images = images.where('dataset', '==', tagRef)
            }
            if (opt.objDivision && opt.objDivision != 'all'){
                if(opt.objDivision=='predetermined'){
                    //images = images.where('set', '==', "")
                    images = images.where('set', '==', "PREDETERMINED")
                }else images = images.where('set', '==', opt.objDivision.toString().toUpperCase())
            }
            if (dataset.type && opt.objtagsType) {
                if (opt.objtagsType == 'labeled') {

                    if(dataset.type=='imageObjectDetection' || dataset.type=='MULTILABEL'){
                        console.log('en OB labeled a por tag:',opt.objByTag)
                       
                        if (opt.objByTag && opt.objByTag != 'all') {
                        
                            let stagRef = $_firebase.firestore().collection("dataset").doc(opt.datasetID.toString()).collection("tag").doc(opt.objByTag.toString())
                            images = images.where('tagsContained', 'array-contains', stagRef)
                            //images = images.orderBy("tags")
                            //if(!opt.order)images = images.orderBy("date", "desc") 
                       
                        } else { 
                            images = images.where('tags', '!=', [])
                            images = images.orderBy("tags")
                            //if(!opt.order)images = images.orderBy("date", "desc") 
                        } //.orderBy("date", "desc") 
                    }
                    if(dataset.type=='MULTICLASS')images = images.where('tag', '!=', unclassRef).orderBy("tag") //.orderBy(order.order, order.type) //.orderBy("date", "desc")

                }else if (opt.objtagsType == 'nolabel'){

                    if(dataset.type=='imageObjectDetection' || dataset.type=='MULTILABEL')images = images.where('tags', '==', []) //.orderBy("date", "desc")
                    if(dataset.type=='MULTICLASS')images = images.where('tag', '==', unclassRef).orderBy("tags") //.orderBy("date", "desc")

                }else if(dataset.type=='MULTILABEL' && opt.objtagsType=='anomaly'){
                    if (opt.objByTag && opt.objByTag != 'all' && opt.objByTag != '0') {
                        let stagRef = $_firebase.firestore().collection("dataset").doc(opt.datasetID.toString()).collection("tag").doc(opt.objByTag.toString())
                        images = images.where('tagsContained', 'array-contains', stagRef)
                        images = images.orderBy("tags")
                        //if(!opt.order)images = images.orderBy("date", "desc") 
                    }
                }
            } else {
                //if (order && order.order && order.type) images = images.orderBy(order.order, order.type)
            }

            //if(dataset.type=='imageObjectDetection') revisar ordre a tots els tipus
            images = images.orderBy("date", "desc") 

            if (opt.pagination){
                if (opt.action && opt.action == "init" && pagination.init) {
                    images = images.startAt(pagination.init)
                } else if (opt.action && opt.action == "next" && pagination.last){ images = images.startAfter(pagination.last) }
                if (opt.action && opt.action == "prev" && pagination.first) {
                    images = images.endBefore(pagination.first)
                    if (pagination.perPage) images = images.limitToLast(pagination.perPage)
                } else if (opt.action && opt.action == "end" && pagination.end) {
                    images = images.endBefore(pagination.end)
                    if (pagination.perPage) images = images.limitToLast((pagination.perPage - 1))
                } else { 
                    if (opt.pagination && pagination.perPage) images = images.limit(pagination.perPage)
                }
            }
  

            if(!opt.pagination && opt.limit)images = images.limit(opt.limit)
            
            
            var dataDivisionOnlyLabeled = false
            if(opt.objtagsType && opt.objtagsType=='labeled')dataDivisionOnlyLabeled = true
            if (dataset.type && (dataset.type=='MULTICLASS') && opt.objByTag && opt.objByTag!= 'all')
                media.dataDivision = await this.getDataDivision(opt.datasetID.toString(), dataDivisionOnlyLabeled, opt.objByTag, opt.tagMap ? tagMap : false)
           
            
            let snap = await images.get()
            //console.log('getImages:',snap.size, opt)
            snap.forEach(async (doc) => {
                let p = doc.data()
                console.log("id:",doc.id, "date", p.date)
                let validTag = true
                p.id = doc.id
                p.tag = p.tag.path
                p.tagName = p.tag.toString().split('/')
                p.fileName = p.name.toString().split('/')
                if(p.date)p.createdDate = helper.getTimestampDate(p.date,'full')
                if(p.updatedAt)p.updatedDate = helper.getTimestampDate(p.updatedAt.toDate(),'full')
                else p.updatedDate = p.createdDate
                p.img_base64_val = p.imageData && p.imageData._delegate._byteString.binaryString ? btoa(p.imageData._delegate._byteString.binaryString) : null

                if(opt.tagMap){
                    validTag = false
                    if(dataset.type && (dataset.type == 'imageObjectDetection' || dataset.type == 'MULTILABEL') && p.tags && p.tags.length){
                        for(let i=0;i<p.tags.length;i++){   
                            let inMap = false
                            Object.keys(opt.tagMap.tags).forEach(key => {
                               if(opt.tagMap.tags[key].tags[p.tags[i].tag.path.toString().split('/').pop()])inMap = true
                            });
                            if(inMap){validTag = true; break  }
                        }
                    }else if(dataset.type && (dataset.type == 'MULTICLASS') && p.tag){ 
                        let inMap = false
                        Object.keys(opt.tagMap.tags).forEach(key => {
                            if(p.tag && opt.tagMap.tags[key].tags[p.tag.toString().split('/').pop()])inMap = true
                        });
                        if(inMap){validTag = true; }
                    }
                }

                if(validTag)media.media.push(p)
            });
            media.count   = media.media.length
            
            //ORDER OBJECTS AND MULTILABEL IF REQUEST ORDER
           //if(media.count && (dataset.type=='imageObjectDetection' || dataset.type == 'MULTILABEL') && opt.order)media.media  = await image.sortImagesByDate(media.media , 'createdDate' ) //'updatedDate'
           
            //PAGINATION
            if (opt.pagination) {

                if ((dataset.type == 'MULTICLASS') && opt.objByTag){
                    if(opt.objByTag == 'all'){
                        var dsDivision = await this.getDataDivision(opt.datasetID, dataDivisionOnlyLabeled, false, opt.tagMap ? tagMap : false)
                        if(dsDivision && opt.objDivision && opt.objDivision != 'all')pagination.total = dsDivision[opt.objDivision]  
                        else pagination.total = dsDivision["total"]
                    }else{
                        if(media.dataDivision && opt.objDivision && opt.objDivision != 'all')pagination.total = media.dataDivision[opt.objDivision]
                        else pagination.total = media.dataDivision["total"]
                    }
                }
                
                if ((dataset.type=='imageObjectDetection' || dataset.type == 'MULTILABEL') && opt.objtagsType){
                    var dsDivision = await this.getDataDivision(opt.datasetID, dataDivisionOnlyLabeled, false, opt.tagMap ? tagMap : false)
                    if(opt.objtagsType=='all'){
                        if(dsDivision && opt.objDivision && opt.objDivision != 'all')pagination.total = dsDivision[opt.objDivision]  
                        else pagination.total = dsDivision["total"]
                    }else if(opt.objtagsType=='labeled'){
                        media.objtagsCount  = await this.getTagsCounter(opt.datasetID, false, { labeledImages: true, tagMap: opt.tagMap ? opt.tagMap : false })
                        pagination.total    = media.objtagsCount.tagslabeledImg && media.objtagsCount.tagslabeledImg[opt.objByTag] ? media.objtagsCount.tagslabeledImg[opt.objByTag] : 0
                        if(opt.objByTag == 'all'){
                            media.dataDivision  = await this.getDataDivision(opt.datasetID.toString(), true, false, opt.tagMap ? tagMap : false)
                            if(media.dataDivision && opt.objDivision && opt.objDivision != 'all')pagination.total = media.dataDivision[opt.objDivision]
                            else pagination.total = media.dataDivision["total"]
                        }else{
                            media.dataDivision  = await this.getDataDivision(opt.datasetID.toString(), true, opt.objByTag, opt.tagMap ? tagMap : false)
                            if(media.dataDivision && opt.objDivision && opt.objDivision != 'all')pagination.total = media.dataDivision[opt.objDivision]
                            else pagination.total = media.dataDivision["total"]

                            /*if(opt.objByTag!='all'){
                                
                            }else{
                             media.dataDivision = await this.getDataDivision(opt.datasetID.toString(), true, opt.objByTag)
                             if(media.dataDivision && opt.objDivision && opt.objDivision != 'all')pagination.total = media.dataDivision[opt.objDivision]
                             else pagination.total = media.dataDivision["total"]
                            }*/
                        }
                    }else if(opt.objtagsType=='normal'){
                        media.objtagsCount  = await this.getTagsCounter(opt.datasetID, false, { labeledImages: true, tagMap: opt.tagMap ? opt.tagMap : false })
                        pagination.total    = media.objtagsCount.tagslabeledImg && media.objtagsCount.tagslabeledImg[opt.objByTag] ? media.objtagsCount.tagslabeledImg[opt.objByTag] : 0
                        media.dataDivision  = await this.getDataDivision(opt.datasetID.toString(), true, "OK", opt.tagMap ? tagMap : false)
                        if(media.dataDivision)pagination.total = media.dataDivision["total"]
                    }else if(opt.objtagsType=='anomaly'){
                        media.objtagsCount  = await this.getTagsCounter(opt.datasetID, false, { labeledImages: true, tagMap: opt.tagMap ? opt.tagMap : false })
                        pagination.total    = media.objtagsCount.tagslabeledImg && media.objtagsCount.tagslabeledImg[opt.objByTag] ? media.objtagsCount.tagslabeledImg[opt.objByTag] : 0

                        if(opt.objByTag == 'all')media.dataDivision  = await this.getDataDivision(opt.datasetID.toString(), false, "0", opt.tagMap ? tagMap : false)
                        else media.dataDivision  = await this.getDataDivision(opt.datasetID.toString(), false, opt.objByTag, opt.tagMap ? tagMap : false)

                        if(media.dataDivision)pagination.total = media.dataDivision["total"]
                    }else if(opt.objtagsType=='nolabel'){
                        //let objtagsCount    = await this.getTagsCounter(opt.datasetID, true, false, opt.tagMap ? tagMap : false)
                        //pagination.total    = objtagsCount.nolabel
                        media.dataDivision  = await this.getDataDivision(opt.datasetID.toString(), false, "filter-notlabel", opt.tagMap ? tagMap : false)
                        if(media.dataDivision && opt.objDivision && opt.objDivision != 'all')pagination.total = media.dataDivision[opt.objDivision]
                        else pagination.total = media.dataDivision["total"]
                    }
                }

                /*if (dataset.type=='imageObjectDetection' && opt.objByTag){
                    let tags         = await this.getTagsCounter(opt.datasetID)
                    pagination.total = !opt.objByTag || opt.objByTag == 'all' ? tags.count : tags.tags[opt.objByTag] ? tags.tags[opt.objByTag] : 0
                    if (opt.objtagsType && opt.objtagsType == 'nolabel')pagination.total = tags.tags["Unclassified"]
                }*/

                if (snap.docs && snap.docs[0]){
                    if(!pagination.init)pagination.init = snap.docs[0];
                    pagination.first = snap.docs[0];
                    pagination.last = snap.docs[snap.docs.length - 1];
                } 
                if (opt.action == 'init') {
                    pagination.currentPage = 0;
                } else if (opt.action == 'prev') {
                    pagination.prev = pagination.next = false;
                    pagination.currentPage--;
                } else if (opt.action == 'next') {
                    pagination.prev = pagination.next = false;
                    pagination.currentPage++;
                } else if (opt.action == 'end') {
                    pagination.currentPage = pagination.pages - 1;
                }
                pagination.pages = Math.ceil(pagination.total/pagination.perPage)
                if((pagination.currentPage+1)==pagination.pages){ pagination.next = false; }else{ pagination.next = true; }
                if(pagination.currentPage==0){ pagination.prev = false; }else{ pagination.prev = true;}
                if (media.media.length)media.pagination = pagination
            }
            //END PAGINATION

        }
        if(opt.resume){
            let resume = { count: 0, images: [] }
            for(let i=0;i<media.media.length;i++){ resume.images.push({ name: media.media[i].name, uri: media.media[i].uri, set: media.media[i].set}) }
            resume.count = resume.images.length
            return resume
        }
        if(opt.preview)return media.media[0] && media.media[0].imageData ? "data:image/png;base64,"+ (btoa(media.media[0].imageData._delegate._byteString.binaryString)) : false
        return media
    },
    /*
     getDataDivision: async function(datasetID, onlyLabeled = false, byTag = false , tagMap = false) {
        let dataset         = await this.get(datasetID)
        let countSetImages  = { total: 0, train: 0, test: 0, validation: 0, predetermined: 0 }
        let snap            = false
        let images      = $_firebase.firestore().collection('image')
        let tagRef      = $_firebase.firestore().collection("dataset").doc(datasetID.toString())
        images          = images.where('dataset', '==', tagRef)
        if(byTag){
            let _tgRef  = $_firebase.firestore().collection("dataset").doc(datasetID.toString()).collection("tag").doc(byTag.toString())
            images      = images.where('tag', '==', _tgRef)
        }
        snap            = await images.get()
        snap.forEach(async (doc) => {
            let p = doc.data()
            let check = true;
            if (dataset.type && (dataset.type == 'imageObjectDetection' || dataset.type == 'MULTILABEL') && onlyLabeled && (!p.tags || !p.tags.length)) check = false
            if (dataset.type && dataset.type == 'MULTICLASS') {
                let tagStr = p.tag.path.toString().split('/').pop()
                if (dataset.type && onlyLabeled && (!p.tag || tagStr == "0")) check = false
            }

            if(tagMap){
                check = false;
                if(dataset.type && (dataset.type == 'imageObjectDetection' || dataset.type == 'MULTILABEL') && p.tags){
                    for(let i=0;i<p.tags.length;i++){   
                        let inMap = false
                        Object.keys(tagMap.tags).forEach(key => {
                           if(tagMap.tags[key].tags[p.tags[i].tag.path.toString().split('/').pop()])inMap = true
                        });
                        if(inMap){ check = true; break; }
                    }
                }else if(dataset.type && (dataset.type == 'MULTICLASS') && p.tag){ 
                    let inMap = false
                    Object.keys(tagMap.tags).forEach(key => {
                        if(p.tag && p.tag.path && tagMap.tags[key].tags[p.tag.path.toString().split('/').pop()])inMap = true
                    });
                    if(inMap){ check = true; }
                }
            }

            if (check) {
                if (p.set) {
                    countSetImages[p.set.toLowerCase()]++;
                } else { countSetImages.predetermined++; }
                countSetImages.total++;
            }
        });
        return countSetImages
    },
    */

    getDataDivision: async function(datasetID, onlyLabeled = false, byTag = false , tagMap = false) {
        //console.log('getDataDivision', datasetID, "onlyLabeled: " + onlyLabeled, "byTag: " + byTag, "tagMap", tagMap)
        let dataset         = await this.get(datasetID)
        let countSetImages  = { total: 0, train: 0, test: 0, validation: 0, predetermined: 0 }
        let tags            = await this.getTags(datasetID)

        if(Object.keys(tags).length){ 

            for (const k of Object.keys(tags)){
                let countTag = true

                if(byTag && byTag.toString()!= k)countTag = false
                if (dataset.type && (dataset.type == 'imageObjectDetection' || dataset.type == 'MULTILABEL') && onlyLabeled && !tags[k].annotationCounter)countTag = false
                if (dataset.type && dataset.type == 'MULTICLASS' && onlyLabeled && tags[k].unclassified)countTag = false

                if(countTag && tagMap){
                    countTag  = false
                    let inMap = false
                    Object.keys(tagMap.tags).forEach(key => {
                        if(tagMap.tags[key].tags[k])inMap = true
                    });
                    if(inMap)countTag = true
                }

                if(countTag){
                    countSetImages.total          += tags[k].imageCounter 
                    countSetImages.train          += tags[k].trainCounter           ? tags[k].trainCounter          : 0
                    countSetImages.test           += tags[k].testCounter            ? tags[k].testCounter           : 0
                    countSetImages.validation     += tags[k].validationCounter      ? tags[k].validationCounter     : 0
                    countSetImages.predetermined  += tags[k].predeterminedCounter   ? tags[k].predeterminedCounter  : 0
                }
            }

            if (dataset.type && (dataset.type == 'imageObjectDetection' || dataset.type == 'MULTILABEL')){
                
                console.log('in data division count with '+dataset.type)
                if(!byTag)countSetImages.total   = tags["0"].imageCounter  
                if((!byTag || byTag == "filter-notlabel") && tags["0"].labeled){

                    if(byTag=="filter-notlabel")onlyLabeled = false
                    
                    countSetImages.train          = tags["0"].labeled.trainCounter;         if(!onlyLabeled)countSetImages.train            += tags["0"].trainCounter
                    countSetImages.test           = tags["0"].labeled.testCounter;          if(!onlyLabeled)countSetImages.test             += tags["0"].testCounter
                    countSetImages.validation     = tags["0"].labeled.validationCounter;    if(!onlyLabeled)countSetImages.validation       += tags["0"].validationCounter
                    countSetImages.predetermined  = tags["0"].labeled.predeterminedCounter; if(!onlyLabeled)countSetImages.predetermined    += tags["0"].predeterminedCounter

                    if(byTag=="filter-notlabel"){
                        countSetImages.train         -= tags["0"].labeled.trainCounter
                        countSetImages.test          -= tags["0"].labeled.testCounter
                        countSetImages.validation    -= tags["0"].labeled.validationCounter
                        countSetImages.predetermined -= tags["0"].labeled.predeterminedCounter
                        countSetImages.total          = parseInt(countSetImages.train) + parseInt(countSetImages.test) + parseInt(countSetImages.validation) + parseInt(countSetImages.predetermined)
                        console.log('set total 1', countSetImages.total)
                    }
                    
                    if(onlyLabeled){ // || dataset.type == 'MULTILABEL'
                        countSetImages.total = parseInt(countSetImages.train) + parseInt(countSetImages.test) + parseInt(countSetImages.validation) + parseInt(countSetImages.predetermined)
                        console.log('set total 2', countSetImages.total, byTag, onlyLabeled, dataset.type)
                    }

                    if(dataset.type == 'MULTILABEL'){
                        countSetImages.train            = parseInt(tags["0"].labeled.trainCounter)
                        countSetImages.test             = parseInt(tags["0"].labeled.testCounter)
                        countSetImages.validation       = parseInt(tags["0"].labeled.validationCounter)
                        countSetImages.predetermined    = parseInt(tags["0"].labeled.predeterminedCounter)
                    }

                }  

                if(dataset.type == 'MULTILABEL' && byTag){
                    countSetImages.train          = tags[byTag.toString()].trainCounter
                    countSetImages.test           = tags[byTag.toString()].testCounter
                    countSetImages.validation     = tags[byTag.toString()].validationCounter
                    countSetImages.predetermined  = tags[byTag.toString()].predeterminedCounter
                    
                    console.log('init count', byTag, countSetImages.train, countSetImages.test, countSetImages.validation, countSetImages.predetermined)

                    /*if(byTag.toString()=="0"){
                        countSetImages.train            += parseInt(tags["0"].labeled.trainCounter-tags["OK"].trainCounter)
                        countSetImages.test             += parseInt(tags["0"].labeled.testCounter-tags["OK"].testCounter)
                        countSetImages.validation       += parseInt(tags["0"].labeled.validationCounter-tags["OK"].validationCounter)
                        countSetImages.predetermined    += parseInt(tags["0"].labeled.predeterminedCounter-tags["OK"].predeterminedCounter)
                    }*/

                    countSetImages.total          = parseInt(countSetImages.train) + parseInt(countSetImages.test) + parseInt(countSetImages.validation) + parseInt(countSetImages.predetermined)
                    console.log('set total 3', byTag, countSetImages.total)
                }
                
            }
            
        }

        
        /*
        let snap            = false
        let images          = $_firebase.firestore().collection('image')
        let tagRef          = $_firebase.firestore().collection("dataset").doc(datasetID.toString())
        images              = images.where('dataset', '==', tagRef)
        if(byTag){
            let _tgRef  = $_firebase.firestore().collection("dataset").doc(datasetID.toString()).collection("tag").doc(byTag.toString())
            images      = images.where('tag', '==', _tgRef)
        }
        snap            = await images.get()
        snap.forEach(async (doc) => {
            let p = doc.data()
            let check = true;
            if (dataset.type && (dataset.type == 'imageObjectDetection' || dataset.type == 'MULTILABEL') && onlyLabeled && (!p.tags || !p.tags.length)) check = false
            if (dataset.type && dataset.type == 'MULTICLASS') {
                let tagStr = p.tag.path.toString().split('/').pop()
                if (dataset.type && onlyLabeled && (!p.tag || tagStr == "0")) check = false
            }

            if(tagMap){
                check = false;
                if(dataset.type && (dataset.type == 'imageObjectDetection' || dataset.type == 'MULTILABEL') && p.tags){
                    for(let i=0;i<p.tags.length;i++){   
                        let inMap = false
                        Object.keys(tagMap.tags).forEach(key => {
                           if(tagMap.tags[key].tags[p.tags[i].tag.path.toString().split('/').pop()])inMap = true
                        });
                        if(inMap){ check = true; break; }
                    }
                }else if(dataset.type && (dataset.type == 'MULTICLASS') && p.tag){ 
                    let inMap = false
                    Object.keys(tagMap.tags).forEach(key => {
                        if(p.tag && p.tag.path && tagMap.tags[key].tags[p.tag.path.toString().split('/').pop()])inMap = true
                    });
                    if(inMap){ check = true; }
                }
            }

            if (check) {
                if (p.set) {
                    countSetImages[p.set.toLowerCase()]++;
                } else { countSetImages.predetermined++; }
                countSetImages.total++;
            }
        });

        */
        console.log('IN getDataDivision',countSetImages)
        return countSetImages
    },

    getMaskCounter: async function(datasetID, Tag = false) {
        let countMaskImages = 0
        let images          = $_firebase.firestore().collection('image')
        let tagRef          = $_firebase.firestore().collection("dataset").doc(datasetID.toString())
        images              = images.where('dataset', '==', tagRef)
        let _tgRef          = $_firebase.firestore().collection("dataset").doc(datasetID.toString()).collection("tag").doc(Tag.toString())
        images              = images.where('tag', '==', _tgRef)
        let snap            = await images.get()
        snap.forEach(async (doc) => {
            let p = doc.data()
            if(p.mask && p.mask.imageJson)countMaskImages++
        });
        return countMaskImages
    },

    setRandDataDivision: async function(datasetID, onlyLabeled = false) {

        //const image = require("./image");
        let resp    = { status: "error", error: false, dataset: false, options: {} }
        if(datasetID){
            resp.dataset                = datasetID
            resp.options.onlyLabeled    = onlyLabeled
            let qry                     = "Are you sure you want to assign the data division randomly?" + "\n" 
                                        + "Images: " + ( onlyLabeled ? "Only labeled" : "All" ) + "\n"
                                        + "Important: This action is irreversible"
            if (confirm(qry)){
                let dataset         = await this.get(datasetID)
                let newDivison      = { total: 0, train: 0, test: 0, validation: 0, predetermined: 0 }
                resp.oldDivision    = await this.getDataDivision(datasetID, onlyLabeled)
                let images          = $_firebase.firestore().collection('image')
                let tagRef          = await $_firebase.firestore().collection("dataset").doc(datasetID.toString())
                images              = images.where('dataset', '==', tagRef)
                let snap            = await images.get()
                var imageCount      = snap.docs.length
                resp.options.testPercentage          = 10 
                resp.options.validationPercentage    = 10 
                /* eslint-disable */
                let maskSet         = Array.from({length: imageCount }, (_, index) => "TRAIN");
                let train           = Array.from({length: imageCount }, (_, index) => index);
                for (let i = 0; i <= Math.round(imageCount * parseInt(resp.options.testPercentage) / 100); i++) {
                    let _rand               = Math.floor(Math.random() * train.length);
                    maskSet[train[_rand]]   = "TEST"
                    train                   = train.filter(function (item, idx) {return idx !== _rand });
                }
                for (let i = 0; i <= Math.round(imageCount * parseInt(resp.options.validationPercentage) / 100); i++) {
                    let _rand               = Math.floor(Math.random() * train.length);
                    maskSet[train[_rand]]   = "VALIDATION"
                    train = train.filter(function (item, idx) { return idx !== _rand });
                } 
                /* eslint-enable */
                var counterImg = 0
                snap.forEach(async (doc) => {
                    let p = doc.data()
                    p.id  = doc.id;
                    let check = true;
                    if (dataset.type && dataset.type == 'imageObjectDetection' && onlyLabeled && (!p.tags || !p.tags.length)) check = false
                    if (dataset.type && (dataset.type == 'MULTICLASS' || dataset.type == 'MULTILABEL')) {
                        let tagStr = p.tag.path.toString().split('/').pop()
                        if (dataset.type && (dataset.type == 'MULTICLASS' || dataset.type == 'MULTILABEL') && onlyLabeled && (!p.tag || tagStr == "0")) check = false
                    }
                    if (check) {
                        if (maskSet[counterImg]) {
                            if(p.id)image.setSet(p.id, maskSet[counterImg].toUpperCase())
                            newDivison[maskSet[counterImg].toLowerCase()]++;
                        } else { 
                            if(p.id)image.setSet(p.id, "PREDETERMINED")
                            newDivison.predetermined++; 
                        }
                        newDivison.total++;
                    }
                    counterImg++;
                });
                resp.newDivision  = newDivison
                resp.status       = "success"
                await event.saveEvent('dataset.datadivision.random',{ dataset: datasetID, response: resp }, false); //uid: useStore().state.main.User.uid, 
            }else{
                resp.response         = "Randomly set assignement is canceled by user"
                resp.currentDivision  = await this.getDataDivision(datasetID, onlyLabeled)
            }
            resp.status    = "success"
        }else{ resp.error = "dataset Id is required" } 
    return resp 
    },

    validateToTrain: async function(datasetID , opt = {}) {
        let dataset  = await this.get(datasetID)
        let validation  = { 
                            dataset:   dataset.name,
                            type:      dataset.type,
                            tags:      await this.getTagsCounter(datasetID, false, { tagMap: opt.tagMap ? opt.tagMap : false } ),
                            division:  opt.noDivision ? false : await this.getDataDivision(datasetID, true, false, opt.tagMap ? opt.tagMap : false ),
                            validated: true,
                            errors:    [],
                            warnings:  [],
                            trainingImages: await this.getImages({ datasetID: datasetID, objtagsType: 'labeled', resume: true, tagMap: opt.tagMap ? opt.tagMap : false }),
                          }

        if(opt.tagMap)validation.tagMap = opt.tagMap

        if(validation.trainingImages.count<20){ validation.validated = false; validation.errors.push("Must have at least 20 labeled images") }  
        if(validation.trainingImages.images.length){
            let repeatName = { imgNames: {} , validate: true }
            for(let i=0;i<validation.trainingImages.images.length;i++){
                if(repeatName.imgNames[validation.trainingImages.images[i].name]){ repeatName.validate = false; }else{ repeatName.imgNames[validation.trainingImages.images[i].name] = true }
            }
            if(!repeatName.validate){  validation.warnings.push("There are repeated image names") }  
        }
        if(validation.type=="MULTICLASS"){
            if(!validation.tags.tags || !Object.keys(validation.tags.tags).length){
                validation.errors.push("No defined required tags, must have at least 2 tags")
                validation.validated = false
            }else{
                //if(Object.keys(validation.tags.tags).length<2){ validation.validated = false; validation.errors.push("Must have at least 2 tags") }
                for (let index in validation.tags.tags) {
                    if(validation.tags.tags[index]<10){ validation.validated = false; validation.errors.push("The "+index+" tag must have at least 10 images for the tag") }
                }
            }
        } 

        if(validation.type=="imageObjectDetection" || validation.type=="MULTILABEL"){
            if(!validation.tags.tagslabeled || !Object.keys(validation.tags.tagslabeled).length){
                validation.errors.push("No defined required tags, must have at least 2 tags")
                validation.validated = false
            }else{
                for (let index in validation.tags.tagslabeled) {
                    if(validation.tags.tagslabeled[index]<10){ validation.validated = false; validation.errors.push("The "+index+" tag must have at least 10 images for the tag") }
                }
            }
        }

        if(!opt.noDivision){
            if(!Object.keys(validation.division).length){ validation.validated = false; validation.errors.push("No defined data division")
            }else{
                validation.division.percentages = {
                    train:  validation.division['train'] ? ((validation.division['train'] * 100) / validation.division['total']).toFixed(2): 0,
                    test:   validation.division['test'] ? ((validation.division['test'] * 100) / validation.division['total']).toFixed(2): 0,
                    validation: validation.division['validation'] ? ((validation.division['validation'] * 100) / validation.division['total']).toFixed(2): 0
                }
                if(validation.division.percentages.train< 70){ validation.validated = false; validation.errors.push("Must have at least 70% train") }
                if(validation.division.percentages.test< 10){ validation.validated = false; validation.errors.push("Must have at least 10% test") }
                if(validation.division.percentages.validation< 10){ validation.validated = false; validation.errors.push("Must have at least 10% validation") }
        
                if(validation.type=="MULTICLASS" || validation.type=="MULTILABEL"){
                    if(validation.division.test<10){ validation.validated = false; validation.errors.push("Must have at least 10 test images") }
                    if(validation.division.validation<10){ validation.validated = false; validation.errors.push("Must have at least 10 validation images") }
                    if(validation.division.train<10){ validation.validated = false; validation.errors.push("Must have at least 10 train images") }
                }
        
                validation.division.chart = { 
                        labels:                 ["test","validation","train"], 
                        datasets:               [{ data: [validation.division.test, validation.division.validation, validation.division.train], 
                        backgroundColor:        [helper.StringtoHex("test"),helper.StringtoHex("validation"),helper.StringtoHex("train")],
                        hoverBackgroundColor:   [helper.StringtoHex("test"),helper.StringtoHex("validation"),helper.StringtoHex("train")],
                        borderWidth:            5,
                        borderColor:            "#fff"}]}
            }
        }
        return validation
    },

    train: async function(trainingData) {
        let resp = { status: "error", error: false }
        if(trainingData.datasetID){
            resp.apiQry  = 'api/model/create/model/' + trainingData.datasetID + '-' + trainingData.modelName + '-' + trainingData.trainBudget + '-' + trainingData.type
            if (trainingData.annotationSetId)resp.apiQry  += '-' + trainingData.annotationSetId
            let trainingResp = await other.httpsCallable(resp.apiQry)
            if(trainingResp.data){
                resp.operationId    = trainingResp.data.name.toString().split('/').pop()
                resp.operationName  = trainingResp.data.name.toString()
                resp.status         = "success"
            }else{ resp.error = "failed to init training api request to create a model" } 
            /*
            resp.operationId    = "9118112284034465792"
            resp.operationName  = 'projects/147966316554/locations/us-central1/datasets/3959669026086453248/operations/9118112284034465792'
             */
            resp.status = "success"
        }else{ resp.error = "dataset Id is required" } 
        if(resp.error)await event.saveEvent('dataset.train.vertex',{ dataset: trainingData.datasetID, response: resp }, true ); //uid: useStore().state.main.User.uid, 
        return resp 
    },

    getDatasetAnnotationSetList: async function(datasetID) {
        let resp = { status: "error", error: false, last: false }
        if(datasetID){
            resp.apiQry = 'api/model/annotationset/dataset_id/' + datasetID
            let annotationsgResp = await other.httpsCallable(resp.apiQry)
            if(annotationsgResp.data){
                resp.annotationSet  = annotationsgResp.data
                if(annotationsgResp.data[0] && annotationsgResp.data[0].name){
                    //ordenar por key createTime que es un objeto que contiene seconds y nanos y obtener el ultimo de la lista en una variable
                    resp.annotationSet.sort(function(a, b) { return b.createTime.seconds - a.createTime.seconds;  })
                    resp.last  = resp.annotationSet[0].name.toString().split('/').pop()
                }
                resp.status         = "success"
            }else{ resp.error = "failed to get annotation set list" } 
        }else{ resp.error = "Automl dataset Id is required" } 
        return resp       
    },

    getStatus: async function(datasetID) {
        let dataset           = await this.get(datasetID)
        let lastImportEvent   = dataset.automl ? await event.get({ type: "dataset.import" , dataset: dataset.automl, last : true}) : {}
        let lastTrainingEvent = dataset.automl ? await event.get({ type: "dataset.training" , dataset: dataset.automl, last : true}) : {}
        let lastImport        = { inProgress: false }
        let lastTraining      = { inProgress: false }
        let lastUploadZip     = { inProgress: false }
        
        if(dataset.uploadRef)lastUploadZip.uploadRef              = dataset.uploadRef
        if(dataset.uploadStatus)lastUploadZip.uploadStatus        = dataset.uploadStatus
        if(dataset.uploadStatusMsg)lastUploadZip.uploadStatusMsg  = dataset.uploadStatusMsg
        if(dataset.uploadStatus=="processing")lastUploadZip.inProgress = true

        if(Object.keys(lastImportEvent).length){
            if(lastImportEvent.name)lastImport.name                 = lastImportEvent.name
            if(lastImportEvent.payload.dataset)lastImport.datasetId = lastImportEvent.payload.dataset
            if(lastImportEvent.createdAt)lastImport.created         = helper.getTimestampDate(lastImportEvent.createdAt.toDate(),'full')
            if(lastImportEvent.payload.uid)lastImport.uid           = lastImportEvent.payload.uid
            if(lastImportEvent.payload && lastImportEvent.payload.operation){
                let operationName  = lastImportEvent.payload.operationName ? lastImportEvent.payload.operationName : lastImportEvent.payload.operation
                let lastImportOper = await other.httpsCallable('api/model/operation/status/'+ operationName.replace(/\//g, "--"))
                if(lastImportOper.data)lastImport.operation         = { name: lastImportOper.data.name, result: lastImportOper.data.result, done: lastImportOper.data.done } 
                if(lastImport.operation && !lastImport.operation.done)lastImport.inProgress = true
            }
        }

        if(Object.keys(lastTrainingEvent).length){ ///revisar esta parte para eliminar (en dataset getModels ya actualiza id automl)
            if(lastTrainingEvent.name)lastTraining.name                   = lastTrainingEvent.name
            if(lastTrainingEvent.payload.dataset)lastTraining.datasetId   = lastTrainingEvent.payload.dataset
            if(lastTrainingEvent.payload.displayname)lastTraining.model   = lastTrainingEvent.payload.displayname
            if(lastTrainingEvent.createdAt)lastTraining.created           = helper.getTimestampDate(lastTrainingEvent.createdAt.toDate(),'full')
            if(lastTrainingEvent.payload.uid)lastTraining.uid             = lastTrainingEvent.payload.uid
            if(lastTrainingEvent.payload && lastTrainingEvent.payload.operationID){
                let pipName          = lastTrainingEvent.payload.operationID.name ? lastTrainingEvent.payload.operationID.name : lastTrainingEvent.payload.operationID;
                let lastTrainingPip  = await other.httpsCallable('api/model/trainingpipeline/status/' + pipName.replace(/\//g, "--"))
                if(lastTrainingPip.data){ 
                    lastTraining.pipeline  = { 
                        name:        lastTrainingPip.data.name, 
                        displayName: lastTrainingPip.data.displayName, 
                        startTime:   helper.getFbDate(lastTrainingPip.data.startTime),
                        trainBudget: lastTrainingEvent.payload.trainBudget,
                        state:       lastTrainingPip.data.state,
                        error:       lastTrainingPip.data.error,
                        modelToUpload: lastTrainingPip.data.modelToUpload ? lastTrainingPip.data.modelToUpload.name : false,
                        done:        lastTrainingEvent.status && lastTrainingEvent.status=="done" ? true : false
                    } 
                    if(lastTrainingPip.data.state=="PIPELINE_STATE_SUCCEEDED" || lastTrainingPip.data.state=="PIPELINE_STATE_FAILED")lastTraining.pipeline.done = true
                    if(lastTraining.model && lastTraining.pipeline.modelToUpload){
                        let trainedModel = await model.get(lastTraining.model)
                        if(!trainedModel.automl)model.update(lastTraining.model,{ automl : lastTraining.pipeline.modelToUpload.toString().split('/').pop() })
                    }
                }else{ lastTraining.pipeline  = { name: lastTrainingEvent.payload.operationID, error: "not found", trainBudget: lastTrainingEvent.payload.trainBudget, state: "PIPELINE_STATE_FAILED", done: true } }
                if(lastTraining.pipeline && !lastTraining.pipeline.done)lastTraining.inProgress = true
            }
            if(!dataset.trained)await this.update(dataset.id,{ trained : Boolean(true) })
        }   

        let resp = {
            dataset:            datasetID,
            trained:            Object.keys(lastTrainingEvent).length ? true : false,
            inProgress:         lastImport.inProgress || lastTraining.inProgress || lastUploadZip.inProgress ? true : false,
            action:             lastImport.inProgress ? 'importing' : lastTraining.inProgress ? 'training' : lastUploadZip.inProgress ? 'Uploading' : false, 
            import:             lastImport,
            training:           lastTraining,
            uploadZip:          lastUploadZip,
        };
        
        //aws models status
        let datasetModels     = await this.getModels(datasetID)
        if(datasetModels.models.length){
            for(let index in datasetModels.models){
                if(datasetModels.models[index].aws){
                    let model = await aws.getModel(datasetModels.models[index].aws)
                    if(model.response && model.response.ModelDescription && model.response.ModelDescription.Status  && model.response.ModelDescription.Status=="TRAINING"){
                        resp.inProgress = true
                        resp.action     = 'training'
                        resp.training   =  model.response.ModelDescription
                        resp.training.modelName    =  datasetModels.models[index].id
                        resp.training.projectName  =  datasetModels.models[index].aws
                    }
                }
            }
        }
        //aws models status

        return resp
    },

    getLastLog: async function(datasetID, limit = 5, opt = {}) {
        let resp = { status: "error", error: false, log: false }
        if(datasetID){
            let optLog   = { dataset: datasetID, limit: limit }
            if(opt.preview)optLog.preview = true
            if(opt.byDate)optLog.byDate = true
            resp.log     = await event.get(optLog)
            resp.status  = "success"
        }else{ resp.error = "dataset Id is required" } 
        return resp 
    },

    downloadZip: async function(datasetID) {
        let media   = await this.getImages({ datasetID: datasetID }) 
        let tags    = await this.getTags(datasetID)   
        let zip     = { name: "zip", count: 0, files: [], folders: [] }
        var nowDate = new Date()
        if(media.count){
            for (var i = 0; i < Object.keys(media.media).length; i++) {
                if(media.media[i].uri){
                    let storageUrl = await image.getStorageUrl(media.media[i].uri)
                    if(storageUrl.url){
                        zip.files.push({ 
                            name:  media.media[i].name.substr(media.media[i].uri.lastIndexOf("/")+1).replace(/\s+/g, '_'),
                            tag:   media.media[i].tagName && media.media[i].tagName[3] ? media.media[i].tagName[3] : false,
                            blob:  fetch(storageUrl.url).then(response => response.blob()),
                        })
                        zip.count++
                    }
                }     
            }  
            var z    = new JSZip() 
            if(media.type && (media.type=='MULTICLASS' || media.type=='MULTILABEL')){
                if(Object.keys(tags).length){ for (const k of Object.keys(tags)){  zip.folders[tags[k].id] = z.folder(tags[k].id); } } //create tags forlder
                for (let i = 0; i < zip.files.length; i++) { if(zip.files[i].tag)zip.folders[zip.files[i].tag].file(zip.files[i].name, zip.files[i].blob, { base64: true }) } //insert folder images
            }else{
                zip.folders[datasetID.replace(/\s+/g, '_')] = z.folder(datasetID.replace(/\s+/g, '_'));
                for (let i = 0; i < zip.files.length; i++) { zip.folders[datasetID.replace(/\s+/g, '_')].file(zip.files[i].name, zip.files[i].blob, { base64: true }) } //insert images
            }
            zip.name = datasetID.replace(/\s+/g, '_')+"_media_"+nowDate.getTime()+"_"+zip.count+".zip"
            await z.generateAsync({type:"blob"}).then(async function (blob) { saveAs(blob,zip.name); });
            await event.saveEvent('dataset.download',{ dataset: datasetID, filename: zip.name, format: "zip", size: zip.files.length }, false); //uid: useStore().state.main.User.uid, 
            return { error: false, status: "success", name: zip.name, images: zip.count, message: "The download will start automatically" }
        }else{ return { error: "Dataset has no images", status: "error" } }
    },

    uploadStorage: async function(datasetID, opt = false) {
        let resp = { status: "error", error: false }
        if(datasetID){
        let tagsCounter = await this.getTagsCounter(datasetID)
        resp.dataset    = datasetID
        if(tagsCounter.count){
            let dataset = await this.get(datasetID)
            if(dataset.automl){
                resp.dataset = datasetID
                resp.csvQry  = 'api/dataset/'+datasetID+'/csv'
                if(opt.test || opt.validation){
                    resp.csvQry += opt.test ? "?test="+opt.test : ""
                    resp.csvQry += opt.validation && opt.test ?  "&" : "" 
                    resp.csvQry += opt.validation ?  "validation="+opt.validation : ""
                }
                if(opt.tagMap){
                    resp.csvQry  += '&tagmap='
                    Object.keys(opt.tagMap.tags).forEach(key => {
                        Object.keys(opt.tagMap.tags[key].tags).forEach(k => {
                            //console.log(key, k, opt.tagMap.tags[key].tags[k])
                            if(k!="count")resp.csvQry += key.replace(/\s/g, "!!-") + "-|-" + k.toString().replace(/\s/g, "!!-") + '--||--'
                        });    
                    });
                }
                console.log('In modle uploadStorage enviamos csv', resp.csvQry)
                let csv  = await other.httpsCallable(resp.csvQry)
                resp.csv = csv.data ? csv.data.training.dataset : false
                if(csv.data && resp.csv){
                    resp.importQry = 'api/model/import/dataset/' + dataset.automl + '-|-' + resp.csv.replace(/\//g, "!!-") + '-|-' + dataset.type.replace(/\//g, "!!-")
                    let importResp = await other.httpsCallable(resp.importQry)
                    if(importResp.data && importResp.data.name){
                        resp.operationId    = importResp.data.name.toString().split('/').pop()
                        resp.operationName  = importResp.data.name.toString()
                        await event.saveEvent('dataset.import', { dataset: dataset.automl, csv: resp.csv, operation: resp.operationId, operationName: resp.operationName }, false) //uid: useStore().state.main.User.uid, 
                        resp.status = "success"
                    }else{ resp.error = "failed to import to Vertex" } 
                }else{ resp.error = "failed to generate csv" } 
            }else{ resp.error = "dataset automl Id is required" }
        }else{ resp.error = "the dataset does not have images" }
    }else{ resp.error = "dataset Id is required" } 
    if(resp.error)await event.saveEvent('dataset.import.vertex',{ dataset: datasetID, response: resp }, true ); //uid: useStore().state.main.User.uid, 
    return resp 
    },

    normalAnomaly: async function(datasetID) {
        let resp = { status: "error", error: false }
        if(datasetID){
             let dataset   = await this.get(datasetID)
             if(dataset.type=="MULTICLASS" || dataset.type=="MULTILABEL"){
                let tags      = await this.getTags(datasetID)
                resp.dataset  = datasetID
                if(Object.keys(tags).length){ 
                    let confirmMap   = false
                    while (!confirmMap) {
                        resp.tagMap  = { normal: [], anomaly: [] }
                        for (const k of Object.keys(tags)){ 
                            let tagName =  tags[k].name.toString().toUpperCase()
                            if(!tags[k].unclassified){
                                if (confirm("Are " + tagName + " anomaly tag?")) resp.tagMap.anomaly.push(tagName)
                                else resp.tagMap.normal.push(tagName)
                            }
                        } 
                        if (confirm("Assignments:\n\n" + "Normal: " + JSON.stringify(resp.tagMap.normal) + "\n" + "Anomaly: " + JSON.stringify(resp.tagMap.anomaly) + "\n\nDo you wish to continue?")){
                            confirmMap   = true;
                            resp.status  = "success"
                        }
                    }
                }else{ resp.error = "dataset does not have tags" }     
            }else{ resp.error = "only for classification type datasets, is " + dataset.type } 
        }else{ resp.error = "dataset Id is required" } 
        return resp 
    },

    uploadS3: async function(datasetID, projectName = false, tagMap=false, uploadImageType = "image/png") {
        let resp = { status: "error", error: false }
        if(datasetID){
            if(!tagMap)tagMap  = await this.normalAnomaly(datasetID)
             resp.response      = await aws.uploadS3(datasetID, tagMap.tagMap ? tagMap.tagMap : tagMap, projectName, false, uploadImageType)
             resp.status        = "success"
             
        }else{ resp.error = "dataset Id is required" } 
        return resp 
    },
   
    uploadImage: async function(opt) {
        let resp            = { status: "success", error: false }
        var nowDate         = new Date()
        let storageConfig   = opt.firebaseConfig ? opt.firebaseConfig :  $_firebase.getConfig()
        let fileName        = opt.name ? opt.name : nowDate.getTime() + opt.name ? "."+opt.name.toString().split('.').pop() : ".png"
        var storageRef      = $_firebase.firebase.app().storage("gs://" + storageConfig.projectId).ref(); 
        var datasetDir      = opt.datasetId.toString().replace(/\s+/g, "-")
        let uploadRef       = await storageRef.child('upload/manual/image/' + datasetDir + "/" + fileName);
        var reader          = new FileReader();
        reader.readAsDataURL(opt.image);
        reader.onloadend    = function() { resp.base64 = reader.result }
        const task          = uploadRef.put(opt.image)
        return new Promise((resolve, reject) => {
            task.on(
                "state_changed",
                () => { },
                async (error) => { 
                resp.status = "error"; resp.error = error; reject
                resolve(resp)
                },
                async () => {
                    let resizeImage = await this.resizeAndConvertImage(resp.base64)
                    let newImage    = {
                        date:      nowDate.getTime(), 
                        imageData: resizeImage,
                        uri:       "gs://"+storageConfig.projectId+"/upload/manual/image/" + datasetDir + "/" + fileName,
                        name:      datasetDir + "/" +fileName,
                        dataset:   $_firebase.firestore().collection("dataset").doc(opt.datasetId),
                        tag:       $_firebase.firestore().collection("dataset").doc(opt.datasetId).collection("tag").doc(opt.tagId ? opt.tagId : "0"),
                        updatedAt: $_firebase.firebase.firestore.FieldValue.serverTimestamp(),
                        tags:      [],
                        tagsContained: []
                    }

                    if(opt.tag)newImage.tag = opt.tag
                    if(opt.tags)newImage.tags = opt.tags
                    let tagIdStr = newImage.tag.path.toString().split('/').pop()
                    let dsTags   = await this.getTags(opt.datasetId, false)
                    if(tagIdStr && !dsTags[tagIdStr])await this.createTag(opt.datasetId, { tag: tagIdStr, name: tagIdStr, unclassified: false })

                    $_firebase.firestore().collection("image").add(newImage).then(async (docRef)=>{
                                //get created image id
                                resp.status     = "success" 
                                resp.uploadedId = docRef.id
                                if(opt.comments)await image.setComments(docRef.id, opt.comments)
                                await event.saveEvent('dataset.upload.image',{ 
                                    uid:      opt.uid ? opt.uid : "", 
                                    status:   resp.status, 
                                    dataset:  opt.datasetId, 
                                    file:     fileName,
                                    comments: opt.comments ? opt.comments : "",  
                                    imageId:  docRef.id }, false);
                                    resolve(resp)  
                            }).catch( (error) => { resp.status = "error"; resp.error = error; reject; resolve(resp) } )
                }
            ); 
        }) 
    },

    fileOnFinalize: async function(opt = {}) {
        let resp  = { status: "error", error: false }
        if(opt.datasetID)await this.update(opt.datasetID,{ uploadStatus: "reintent", uploadStatusMessage: "", uploadRef: opt.filename }) 
        else resp.error = "datasetID is required"
        return resp
    },


    uploadZipReintent: async function(datasetID, usapi) {
        let resp              = { status: "success", error: false }
        //let projectConfig     = project.getConfig()
        const functionsUsApi  = $_firebase.firebase.app().functions(usapi) //projectConfig.functions.usapi
        const action          = await functionsUsApi.httpsCallable('dataset/' + datasetID + '/reintent');
        await action({}).then(() => { }).catch(async (error) => { resp.status = "error"; resp.error = error });
        return resp
    },

    resizeAndConvertImage: async function(img) {
        const { createCanvas, loadImage } = require('canvas')
        const image         = await loadImage(img)
        const canvas        = createCanvas(image.width, image.height)
        const ctx           = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0)
        const resizedCanvas = createCanvas(300, Math.floor(300 * image.naturalHeight / image.width))
        const resizedCtx    = resizedCanvas.getContext('2d')
        resizedCtx.drawImage(canvas, 0, 0, 300, Math.floor(300 * image.naturalHeight / image.width))
        const webpData      = resizedCanvas.toDataURL('image/webp', 0.92)
        const base64String  = Buffer.from(webpData.substring(23), 'base64').toString('base64')
        const webpBlob      = $_firebase.firebase.firestore.Blob.fromBase64String(base64String);
        return webpBlob
    },

    setImagesUpdatedDate: async function(datasetID) { 
        let resp = { status: "error", error: false, processingCount: 0, updateCount: 0 }
        if(datasetID){
            let media = await this.getImages({ datasetID: datasetID })
            console.log('in setImagesUpdatedDate',media)
            if(media.count){  
                resp.processingCount = media.count
                for (var i = 0; i < Object.keys(media.media).length; i++) {
                    if(media.media[i] && !media.media[i].updatedAt){
                        console.log(i+1,media.media[i].id, media.media[i].date, $_firebase.firebase.firestore.Timestamp.fromMillis(media.media[i].date) )  
                        resp.updateCount++
                        await $_firebase.firestore().collection("image").doc(media.media[i].id).set({ updatedAt: $_firebase.firebase.firestore.Timestamp.fromMillis(media.media[i].date) }, { merge: true } )
                    }else console.log('tiene fecha: '+media.media[i].updatedAt)
                }  
                resp.status = "success"
            }else resp.error = "dataset not have images"
        }else resp.error = "dataset Id is required";
        return resp
     }, 

}

module.exports = dataset